import {
  Carousel,
  type CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "components/Carousel";
import QuestsSkeleton from "components/loading/QuestsSkeleton";
import { QuestCard, QuestCardVariants } from "components/quest/QuestCard";
import { useUserState } from "context/userContext";
import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { NavigationEnum } from "router/navigationType";
import useScreenType from "hooks/useScreenType";
import type {
  B2bQuest,
  WorkwayQuestAssignment,
} from "types/__generated__/graphql";

export function AssignedToYouSection({
  questsAssigned,
  loading,
}: {
  questsAssigned: WorkwayQuestAssignment[];
  loading?: boolean;
}) {
  const { isAuthenticated, userAccessState } = useUserState();
  const screenType = useScreenType();
  const [carouselApi, setCarouselApi] = useState<CarouselApi>();

  const unEnrolledAssignedQuests = useMemo(
    () =>
      questsAssigned?.filter(
        (assignment) => !assignment.quest.userProgress?.started
      ) || [],
    [questsAssigned]
  );

  const hasOverdueQuests = useMemo(
    () =>
      questsAssigned?.some(
        (quest) => quest.endDate && new Date() > new Date(quest.endDate)
      ) || false,
    [questsAssigned]
  );

  if (unEnrolledAssignedQuests.length === 0) return null;
  if (loading) return <QuestsSkeleton />;

  return (
    <section className="container px-4 md:px-5 lg:px-5 xl:px-5" style={{ maxWidth: "1800px" }}>
      <div className="flex justify-between">
        <div className="flex flex-col gap-2 md:flex-row">
          <div className="heading-6">Assigned to you</div>
          {hasOverdueQuests && (
            <Link to={`${NavigationEnum.Library}/assigned`}>
              <div className="py-1 px-2 flex items-center gap-2 rounded-[4px] bg-red-50 text-red-600 button-text-small">
                You have overdue Quests
              </div>
            </Link>
          )}
        </div>
        <div className="flex gap-2">
          <Link to={`${NavigationEnum.Library}/assigned`}>
            <button
              type="button"
              className="px-3 py-[6px] rounded-[100px] text-black-600 button-text-medium"
            >
              See all
            </button>
          </Link>
          <div className="gap-1 hidden md:flex lg:hidden px-1">
            <CarouselPrevious onClick={() => carouselApi?.scrollPrev()} />
            <CarouselNext onClick={() => carouselApi?.scrollNext()} />
          </div>
        </div>
      </div>
      <Carousel
        opts={{
          align: "start",
          containScroll: "trimSnaps",
          active: false,
        }}
        orientation="horizontal"
        className="w-full"
        setApi={setCarouselApi}
      >
        <CarouselContent>
          {unEnrolledAssignedQuests
            .sort(
              (a, b) =>
                new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
            )
            .map(({ quest, endDate }) => (
              <CarouselItem
                key={quest.id}
                className="py-4 lg:py-6 w-sm md:w-md lg:w-lg xl:w-xl basis-full md:basis-1/2 lg:basis-1/3 xl:basis-1/4"
              >
                <QuestCard
                  quest={quest as B2bQuest} // workwayQuestAssignment is return Quest but they should be the same maybe??
                  cardVariant={QuestCardVariants.Base}
                  questAssignmentEndDate={endDate}
                  canHover={
                    (screenType.isDesktop || screenType.isLargeDesktop) &&
                    isAuthenticated
                  }
                  userAccessState={userAccessState}
                />
              </CarouselItem>
            ))}
        </CarouselContent>
      </Carousel>
    </section>
  );
}
