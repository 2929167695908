import {
  Carousel,
  type CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "components/Carousel";
import FeatureCardSkeleton from "components/loading/FeatureCardSkeleton";
import { QuestCard, QuestCardVariants } from "components/quest/QuestCard";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import useGetPickedForYouQuestIds from "hooks/useGetPickedForYouQuests";
import { useState } from "react";

import useScreenType from "hooks/useScreenType";

export function PickedForYouSection() {
  const { data: questsData, loading: fetchingQuests } =
    useGetPickedForYouQuestIds({
      first: 4,
    });

  const { isAuthenticated, userAccessState } = useUserState();
  const quests = questsData || [];
  const [carouselApi, setCarouselApi] = useState<CarouselApi>();
  const screenType = useScreenType();

  if (fetchingQuests) return <FeatureCardSkeleton />;

  // Temporary fallback
  if (quests.length === 0) {
    return (
      <div className="px-4 md:px-5 lg:px-5 xl:px-5">
        <div className="heading-6">Picked for you</div>
        <div className="caption-disclaimer">
          No quests available at the moment.
        </div>
      </div>
    );
  }

  const filteredQuests =
    screenType.isMobile || screenType.isLargeDesktop
      ? quests
      : quests.slice(0, 3);

  return (
    <section className="container px-4 md:px-5 lg:px-5 xl:px-5" style={{ maxWidth: "1800px" }}>
      {filteredQuests && (
        <div className="flex flex-col w-full gap-6 md:gap-0">
          <div className="flex justify-between">
            <div className="flex flex-col gap-[2px] justify-center">
              <div className="heading-6">Picked for you</div>
              {isAuthenticated && (
                <div className="caption-disclaimer hidden lg:flex">
                  Quests based on your organization’s goals
                </div>
              )}
            </div>
            {(screenType.isMobile || screenType.isTablet) && (
              <div className="flex gap-1 px-1">
                <CarouselPrevious onClick={() => carouselApi?.scrollPrev()} />
                <CarouselNext onClick={() => carouselApi?.scrollNext()} />
              </div>
            )}
          </div>

          <Carousel
            opts={{
              align: "start",
              loop: false,
            }}
            orientation="horizontal"
            className="w-full"
            setApi={setCarouselApi}
          >
            <CarouselContent>
              {filteredQuests.map((quest) => (
                <CarouselItem
                  key={quest.id}
                  className={`md:py-4 lg:py-6 w-sm md:w-md lg:w-lg basis-full md:basis-1/2 lg:basis-1/3 xl:basis-1/${filteredQuests.length}`}
                >
                  <QuestCard
                    quest={quest}
                    cardVariant={QuestCardVariants.Wordmark}
                    canHover={
                      (screenType.isDesktop || screenType.isLargeDesktop) &&
                      userAccessState === UserAccessStateEnum.Premium
                    }
                    userAccessState={userAccessState}
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
          </Carousel>
        </div>
      )}
    </section>
  );
}
