import { gql } from "@apollo/client";

export const GET_CURRENT_ORGANIZATION = gql`
  query getCurrentOrganization {
    currentOrganization {
      id
      name
      division
      logoFullUrl
      logoSquareUrl
    }
  }
`;

export const GET_PICKED_FOR_YOU_QUESTS = gql`
  query getPickedForYouQuests($first: Int, $orgId: ID!) {
    recommendedB2bQuestsPickedForYou(first: $first, orgId: $orgId) {
      edges {
        node {
          quest {
            id
            type
            slug
            name
            description
            language
            authors {
              name
            }
            coverAsset {
              url
            }
            headshotCoverAsset {
              url
            }
            wordmarkAsset {
              url
            }
            categories {
              name
              type
            }
            userProgress {
              completed
              totalDays
              totalDaysCompleted
              daysCompleted
              totalLessonsCompleted
              totalLessons
              started
              resumePage {
                id
                position
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_RECOMMENDED_QUEST = gql`
  query getRecommendedQuest($first: Int, $orgId: ID!) {
    recommendedB2bQuests(first: $first, orgId: $orgId) {
      edges {
        node {
          quest {
            id
            type
            slug
            name
            description
            language
            authors {
              name
            }
            coverAsset {
              url
            }
            wordmarkAsset {
              url
            }
            releases {
              id
              status
              perpetual
              publishedAt
            }
            userProgress {
              completed
              totalDays
              totalDaysCompleted
              daysCompleted
              totalLessonsCompleted
              totalLessons
              started
              resumePage {
                id
                url
                position
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_QUESTS_FROM_LIBRARY = gql`
  query getQuestsFromLibrary(
    $first: Int
    $status: String
    $membership: QuestMembershipFilterValues
  ) {
    b2bQuests(first: $first, progress: $status, membership: $membership) {
      edges {
        node {
          id
          type
          slug
          name
          description
          language
          categories {
            id
            name
          }
          userProgress {
            totalDays
            totalDaysCompleted
            totalLessons
            totalLessonsCompleted
            resumePage {
              id
              url
              position
              name
            }
          }
          authors {
            name
          }
          coverAsset {
            url
          }
          wordmarkAsset {
            url
          }
          trailerAsset {
            url
            renditions(labels: ["hls", "mp3", "ogg"]) {
              url
              filesize
              contentType
            }
          }
        }
      }
    }
  }
`;

export const GET_QUESTS_BY_CATEGORY = gql`
  query getQuestsByCategory(
    $category: ID
    $membershipFilter: QuestMembershipFilterValues = ALL
    $first: Int = 999
    $owned: Boolean
  ) {
    quests(
      category: $category
      membership: $membershipFilter
      first: $first
      owned: $owned
    ) {
      edges {
        node {
          id
          description
          name
          type
          url
          slug
          language
          trailerAsset {
            url
          }
          headshotCoverAsset {
            url
          }
          wordmarkAsset {
            url
          }
          coverAsset {
            url
            thumbnailUrl
          }
          resources {
            mediaAsset {
              url
              edgeUrl
              contentType
            }
          }
          releases {
            id
            status
            perpetual
            publishedAt
          }
          authors {
            name
          }
          userProgress {
            completed
            totalDays
            totalDaysCompleted
            daysCompleted
            totalLessonsCompleted
            totalLessons
            started
            resumePage {
              id
              name
              duration
              position
              url
            }
          }
        }
      }
    }
  }
`;

export const GET_QUESTS = gql`
  query getQuests(
    $after: String
    $first: Int
    $before: String
    $last: Int
    $search: String
    $progress: String
    $category: ID
    $upcoming: QuestUpcomingFilterValues
    $outcomes: [ID]
    $owned: Boolean
    $language: [String]
    $meditation: QuestMeditationFilterValues
    $programBrandNames: [String]
    $membership: QuestMembershipFilterValues!
  ) {
    b2bQuests(
      after: $after
      first: $first
      before: $before
      last: $last
      search: $search
      progress: $progress
      category: $category
      upcoming: $upcoming
      outcomes: $outcomes
      owned: $owned
      language: $language
      meditation: $meditation
      programBrandNames: $programBrandNames
      membership: $membership
    ) {
      edges {
        node {
          id
          description
          name
          type
          url
          slug
          language
          categories {
            id
            name
          }
          headshotCoverAsset {
            url
          }
          wordmarkAsset {
            url
          }
          coverAsset {
            url
            thumbnailUrl
          }
          authors {
            name
          }
          releases {
            id
          }
          pages {
            id
            url
          }
          userProgress {
            completed
            totalDays
            totalDaysCompleted
            daysCompleted
            totalLessonsCompleted
            totalLessons
            started
            resumePage {
              id
              name
              duration
              position
              url
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const GET_QUEST_PAGE_BY_ID = gql`
  query getQuestPageById($id: ID!) {
    page(id: $id) {
      id
      name
      type
      groupName
      position
      locked
      completed
      duration
      preview
      quest {
        id
        name
        type
        slug
        language
        owned
        authors {
          name
        }
        userProgress {
          totalLessonsCompleted
          totalDaysCompleted
          totalLessons
          totalDays
          started
        }
        pages {
          id
          position
        }
        coverAsset {
          thumbnailUrl
          url
        }
        releases {
          id
          status
          perpetual
          publishedAt
        }
        groups {
          id
          locked
          name
          position
          type
          unlockAfterDays
        }
        pages {
          id
          name
          type
          groupName
          position
          locked
          completed
          duration
          preview
          nextPage {
            id
          }
          coverAsset {
            thumbnailUrl
            url
          }
        }
      }
      nextPage {
        id
      }
      url
      coverAsset {
        thumbnailUrl
        url
      }
      tasks {
        id
        type
        name
        description
        position
        required
        imageUrl
        completed
        coverAsset {
          url
        }
      }
      sections {
        info {
          body
          title
          caption
        }
        type
        position
        media {
          type
          author {
            name
            avatarAsset {
              url
            }
            bioTitle
            headline
          }
          coverAsset {
            url
            edgeUrl
          }
          id
          description
          mediaAsset {
            url
            renditions(labels: ["hls", "mp3", "ogg"]) {
              url
              contentType
            }
            duration
            contentType
            captions {
              id
              language
              label
              default
              isDefault
              url
            }
          }
          title
        }
        primaryAsset {
          contentType
          duration
          url
          duration
          sizeOfFile
          edgeUrl
          filesize
          id
          captions {
            id
            language
            label
            default
            isDefault
            url
          }
          markers {
            id
            name
            status
            time
          }
          renditions(labels: ["hls", "mp3", "ogg"]) {
            contentType
            url
          }
          name
          status
          thumbnailUrl
          userProgress {
            watchProgress
            watchTime
            watched
          }
        }
      }
    }
  }
`;

export const GET_QUEST_TASKS = gql`
  query getQuestTasks($id: ID!) {
    page(id: $id) {
      tasks {
        id
        name
        description
        position
        required
        imageUrl
        coverAsset {
          url
        }
        completed
      }
    }
  }
`;

// export const GET_QUESTS = gql`
//   query getQuests(
//     $after: String
//     $first: Int
//     $before: String
//     $last: Int
//     $search: String
//     $progress: String
//     $category: ID
//     $upcoming: QuestUpcomingFilterValues
//     $outcomes: [ID]
//     $owned: Boolean
//     $language: [String]
//     $meditation: QuestMeditationFilterValues
//     $programBrandNames: [String]
//     $membership: QuestMembershipFilterValues!
//   ) {
//     b2bQuests(
//       after: $after
//       first: $first
//       before: $before
//       last: $last
//       search: $search
//       progress: $progress
//       category: $category
//       upcoming: $upcoming
//       outcomes: $outcomes
//       owned: $owned
//       language: $language
//       meditation: $meditation
//       programBrandNames: $programBrandNames
//       membership: $membership
//     ) {
//       edges {
//         node {
//           id
//           description
//           name
//           type
//           url
//           slug
//           language
//           trailerAsset {
//             url
//           }
//           headshotCoverAsset {
//             url
//           }
//           wordmarkAsset {
//             url
//           }
//           coverAsset {
//             url
//             thumbnailUrl
//           }
//           releases {
//             id
//             status
//             perpetual
//             publishedAt
//           }
//           authors {
//             name
//           }
//           userProgress {
//             completed
//             totalDays
//             totalDaysCompleted
//             daysCompleted
//             totalLessonsCompleted
//             totalLessons
//             started
//             resumePage {
//               id
//               name
//               duration
//               position
//               url
//             }
//           }
//         }
//       }
//       pageInfo {
//         hasNextPage
//         hasPreviousPage
//         startCursor
//         endCursor
//       }
//     }
//   }
// `;

export const GET_CHANNEL_BY_SLUG = gql`
  query getChannelBySlug(
    $slug: String!
    $highlightStatus: CollectionHighlightStatusFilterEnum!
    $limit: Int!
  ) {
    b2bChannelBySlug(slug: $slug) {
      id
      title
      description
      slug
      coverAsset {
        url
      }
      trailerLoopingAsset {
        id
        url
        renditions {
          url
          contentType
        }
      }
      authors {
        name
      }
      collections(limit: $limit, highlightStatus: $highlightStatus) {
        id
        subtitle
        description
        title
        slug
        collectionResources {
          id
          title
          description
          totalDuration
          trailerLoopingAsset {
            id
            url
            renditions(labels: ["hls", "mp3", "ogg"]) {
              url
              contentType
            }
          }
          ... on Resource {
            mediaAsset {
              id
              duration
              filesize
              url
              edgeUrl
              renditions(labels: ["hls", "mp3", "ogg"]) {
                url
                contentType
              }
            }
            type
            subtype
          }
          author {
            name
          }
          coverAsset {
            url
          }
        }
        coverAsset {
          url
        }
        landscapeCoverAsset {
          url
        }
      }
    }
  }
`;

export const GET_QUEST_BY_SLUG_AND_LANGUAGE_B2B = gql`
  query getQuestBySlugAndLanguageB2b($slugAndLanguage: QuestSlugAndLanguage!) {
    b2bQuestBySlugAndLanguage(slugAndLanguage: $slugAndLanguage) {
      id
      name
      description
      slug
      language
      currentAssignment {
        startDate
        endDate
      }
      type
      enrollmentsCount
      publishedAt
      duration
      authors {
        name
        bioTitle
        headline
        avatarAsset {
          url
        }
        bannerAsset {
          url
        }
        metadataTitle
        description
        headline
      }
      categories {
        name
        type
      }
      groups {
        id
        locked
        name
        position
        type
        unlockAfterDays
      }
      userProgress {
        completed
        totalDays
        totalDaysCompleted
        daysCompleted
        totalLessonsCompleted
        totalLessons
        started
        resumePage {
          id
          name
          duration
          position
          url
        }
      }
      wordmarkAsset {
        url
      }
      owned
      pages {
        id
        name
        type
        groupName
        position
        locked
        completed
        duration
        preview
        nextPage {
          id
        }
        url
        coverAsset {
          thumbnailUrl
          url
        }
        tasks {
          id
          type
          name
          description
          position
          required
          imageUrl
          completed
          coverAsset {
            url
          }
        }
        sections {
          info {
            body
            title
          }
          type
          position
          media {
            type
            author {
              name
            }
            coverAsset {
              url
              edgeUrl
            }
            id
            description
            mediaAsset {
              url
              contentType
              captions {
                id
                language
                label
                default
                isDefault
                url
              }
            }
            title
          }
          primaryAsset {
            contentType
            url
            duration
            sizeOfFile
            edgeUrl
            filesize
            id
            captions {
              id
              language
              label
              default
              isDefault
              url
            }
            markers {
              id
              name
              status
              time
            }
            name
            status
            thumbnailUrl
            userProgress {
              watchProgress
              watchTime
              watched
            }
            renditions {
              url
              contentType
            }
          }
        }
      }
      outcomes {
        id
        name
      }
      daysCount
      lessonsCount
      coverAsset {
        url
      }
      trailerAsset {
        thumbnailUrl
        url
        renditions {
          id
          contentType
          url
        }
        captions {
          id
          language
          label
          default
          isDefault
          url
        }
      }
      trailerCoverAsset {
        url
      }
      releases {
        id
        status
        perpetual
        publishedAt
      }
      nextRelease {
        id
        status
        perpetual
        publishedAt
      }
      url
      productId
      owned
      language
      resources {
        isPartOfMembership
        type
        id
        title
        averageRating
        currentRating
        ratingsCount
        author {
          id
          name
          bioTitle
          headline
          avatarAsset {
            url
          }
        }
        mediaAsset {
          contentType
          url
          duration
          sizeOfFile
          renditions(labels: ["hls", "mp3", "ogg"]) {
            url
            contentType
          }
        }
        totalDuration
        description
        coverAsset {
          thumbnailUrl
          url
        }
      }
    }
  }
`;

export const GET_QUEST_TRAILER_BY_SLUG_AND_LANGUAGE = gql`
  query getQuestTrailerBySlugAndLanguage(
    $slugAndLanguage: QuestSlugAndLanguage!
  ) {
    b2bQuestBySlugAndLanguage(slugAndLanguage: $slugAndLanguage) {
      id
      name
      categories {
        id
        name
      }
      trailerAsset {
        thumbnailUrl
        url
        renditions(labels: ["hls", "mp3", "ogg"]) {
          contentType
          url
        }
        captions {
          id
          language
          label
          default
          isDefault
          url
        }
      }
    }
  }
`;

export const GET_QUEST_USER_PROGRESS = gql`
  query getQuestUserProgress($slugAndLanguage: QuestSlugAndLanguage!) {
    b2bQuestBySlugAndLanguage(slugAndLanguage: $slugAndLanguage) {
      id
      userProgress {
        completed
        totalDays
        totalDaysCompleted
        daysCompleted
        totalLessonsCompleted
        totalLessons
        started
        resumePage {
          id
          name
          duration
          position
          url
        }
      }
    }
  }
`;

export const GET_QUEST_BY_SLUG_AND_LANGUAGE = gql`
  query getQuestBySlugAndLanguage($slugAndLanguage: QuestSlugAndLanguage!) {
    b2bQuestBySlugAndLanguage(slugAndLanguage: $slugAndLanguage) {
      id
      name
      description
      slug
      language
      currentAssignment {
        startDate
        endDate
      }
      type
      enrollmentsCount
      publishedAt
      duration
      authors {
        name
        bioTitle
        headline
        avatarAsset {
          url
        }
        bannerAsset {
          url
        }
        metadataTitle
        description
        headline
      }
      categories {
        name
        type
      }
      groups {
        id
        locked
        name
        position
        type
        unlockAfterDays
      }
      userProgress {
        completed
        totalDays
        totalDaysCompleted
        daysCompleted
        totalLessonsCompleted
        totalLessons
        started
        resumePage {
          id
          name
          duration
          position
          url
        }
      }
      wordmarkAsset {
        url
      }
      owned
      pages {
        id
        name
        type
        groupName
        position
        locked
        completed
        duration
        preview
        isFavourite
        nextPage {
          id
        }
        url
        coverAsset {
          thumbnailUrl
          url
        }
        sections {
          info {
            body
            title
          }
          type
          position
          media {
            type
            author {
              name
            }
            coverAsset {
              url
              edgeUrl
            }
            id
            description
            mediaAsset {
              url
              contentType
              captions {
                id
                language
                label
                default
                isDefault
                url
              }
            }
            title
          }
          primaryAsset {
            contentType
            url
            duration
            sizeOfFile
            edgeUrl
            filesize
            id
            captions {
              id
              language
              label
              default
              isDefault
              url
            }
            markers {
              id
              name
              status
              time
            }
            name
            status
            thumbnailUrl
            userProgress {
              watchProgress
              watchTime
              watched
            }
            renditions {
              url
              contentType
            }
          }
        }
      }
      outcomes {
        id
        name
      }
      daysCount
      lessonsCount
      coverAsset {
        url
      }
      trailerAsset {
        thumbnailUrl
        url
        renditions {
          url
          contentType
        }
        captions {
          id
          language
          label
          default
          isDefault
          url
        }
      }
      trailerCoverAsset {
        url
      }
      releases {
        id
        status
        perpetual
        publishedAt
      }
      nextRelease {
        id
        status
        perpetual
        publishedAt
      }
      url
      productId
      owned
      language
      resources {
        isPartOfMembership
        type
        id
        title
        averageRating
        currentRating
        author {
          id
          name
        }
        mediaAsset {
          contentType
          url
          duration
          sizeOfFile
          renditions(labels: ["hls", "mp3", "ogg"]) {
            url
            contentType
          }
        }
        totalDuration
        description
        coverAsset {
          thumbnailUrl
          url
        }
      }
    }
  }
`;

// export const GET_QUEST_BY_SLUG_AND_LANGUAGE_DEPRECATED = gql`
//   query getQuestBySlugAndLanguage($slugAndLanguage: QuestSlugAndLanguage!) {
//     questBySlugAndLanguage(slugAndLanguage: $slugAndLanguage) {
//       id
//       name
//       description
//       slug
//       language
//       type
//       enrollmentsCount
//       publishedAt
//       duration
//       authors {
//         name
//         bioTitle
//         headline
//         avatarAsset {
//           url
//         }
//         bannerAsset {
//           url
//         }
//         metadataTitle
//         description
//         headline
//       }
//       categories {
//         name
//         type
//       }
//       groups {
//         id
//         locked
//         name
//         position
//         type
//         unlockAfterDays
//       }
//       wordmarkAsset {
//         url
//       }
//       pages {
//         id
//         name
//         type
//         groupName
//         position
//         locked
//         completed
//         duration
//         preview
//         nextPage {
//           id
//         }
//         url
//         coverAsset {
//           thumbnailUrl
//           url
//         }
//         tasks {
//           id
//           type
//           name
//           description
//           position
//           required
//           imageUrl
//           completed
//           coverAsset {
//             url
//           }
//         }
//         sections {
//           info {
//             body
//             title
//           }
//           type
//           position
//           media {
//             type
//             author {
//               name
//             }
//             coverAsset {
//               url
//               edgeUrl
//             }
//             id
//             description
//             mediaAsset {
//               url
//               contentType
//               captions {
//                 id
//                 language
//                 label
//                 default
//                 isDefault
//                 url
//               }
//             }
//             title
//           }
//           primaryAsset {
//             contentType
//             url
//             duration
//             sizeOfFile
//             edgeUrl
//             filesize
//             id
//             captions {
//               id
//               language
//               label
//               default
//               isDefault
//               url
//             }
//             markers {
//               id
//               name
//               status
//               time
//             }
//             name
//             status
//             thumbnailUrl
//             userProgress {
//               watchProgress
//               watchTime
//               watched
//             }
//             renditions {
//               url
//               contentType
//             }
//           }
//         }
//       }
//       outcomes {
//         id
//         name
//       }
//       daysCount
//       lessonsCount
//       coverAsset {
//         url
//       }
//       trailerAsset {
//         thumbnailUrl
//         url
//         renditions {
//           id
//           contentType
//           url
//         }
//         captions {
//           id
//           language
//           label
//           default
//           isDefault
//           url
//         }
//       }
//       trailerCoverAsset {
//         url
//       }
//       releases {
//         id
//         status
//         perpetual
//         publishedAt
//       }
//       nextRelease {
//         id
//         status
//         perpetual
//         publishedAt
//       }
//       url
//       productId
//       owned
//       language
//       resources {
//         isPartOfMembership
//         type
//         id
//         title
//         author {
//           name
//         }
//         mediaAsset {
//           contentType
//           url
//           duration
//           sizeOfFile
//         }
//         totalDuration
//         description
//         coverAsset {
//           thumbnailUrl
//           url
//         }
//       }
//     }
//   }
// `;

export const GET_CURRENT_PROFILE = gql`
  query getCurrentProfile {
    currentProfile {
      firstName
      lastName
      email
      role
      spokenLanguages {
        displayName
        iso2Code
      }
      country
      location
      industry
      avatarUrl
      tags
      bio
      userLevel {
        level
      }
    }
  }
`;

// export const GET_LIBRARY = gql`
//   query getLibrary(
//     $first: Int
//     $status: String
//     $membership: QuestMembershipFilterValues
//   ) {
//     b2bQuests(first: $first, progress: $status, membership: $membership) {
//       edges {
//         node {
//           id
//           type
//           name
//           description
//           enrollmentStartedAt
//           courseStartedAt
//           courseEndedAt
//           slug
//           language
//           userProgress {
//             totalDays
//             totalDaysCompleted
//             totalLessons
//             totalLessonsCompleted
//             started
//             endedAt
//             completed
//             resumePage {
//               id
//               name
//               duration
//               position
//               url
//             }
//           }
//           url
//           authors {
//             name
//           }
//           coverAsset {
//             url
//           }
//           resources {
//             mediaAsset {
//               url
//               thumbnailUrl
//               duration
//             }
//           }
//           groups {
//             id
//             locked
//             name
//             position
//             type
//             unlockAfterDays
//           }
//           trailerAsset {
//             url
//             thumbnailUrl
//           }
//           wordmarkAsset {
//             url
//           }
//         }
//       }
//     }
//   }
// `;

export const GET_LANGUAGES = gql`
  query getLanguages($first: Int, $type: LanguageType) {
    languages(first: $first, type: $type) {
      edges {
        node {
          id
          iso2Code
          displayName
        }
      }
      totalCount
    }
  }
`;

export const GET_CATEGORIES = gql`
  query getCategories {
    categories(first: 100, type: "b2b") {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_QUEST_BY_ID = gql`
  query getQuestById($id: ID!) {
    quest(id: $id) {
      id
      description
      name
      type
      url
      slug
      authors {
        name
        avatarAsset {
          url
        }
      }
      headshotCoverAsset {
        url
      }
      outcomes {
        name
      }
      trailerAsset {
        url
        thumbnailUrl
        contentType
      }
      wordmarkAsset {
        url
      }
      type
      daysCount
      lessonsCount
      duration
      averageRating
      coverAsset {
        url
      }
      resources {
        mediaAsset {
          url
          contentType
        }
      }
      categories {
        name
        type
      }
      pages {
        position
        type
        sections {
          type
          primaryAsset {
            url
          }
        }
      }
    }
  }
`;

export const GET_FREE_QUESTS = gql`
  query getFreeQuests($first: Int, $owned: Boolean, $language: [String]) {
    b2bQuests(first: $first, owned: $owned, language: $language) {
      edges {
        node {
          id
          type
          name
          description
          enrollmentStartedAt
          courseStartedAt
          courseEndedAt
          slug
          language
          userProgress {
            totalDays
            totalDaysCompleted
            totalLessons
            totalLessonsCompleted
            started
            endedAt
            completed
            resumePage {
              id
              name
              duration
              position
              url
            }
          }
          url
          authors {
            name
          }
          coverAsset {
            url
          }
          resources {
            mediaAsset {
              url
              thumbnailUrl
              duration
            }
          }
          groups {
            id
            locked
            name
            position
            type
            unlockAfterDays
          }
          trailerAsset {
            url
            thumbnailUrl
          }
          wordmarkAsset {
            url
          }
        }
      }
      totalCount
    }
  }
`;

export const GET_QUESTS_ASSIGMENTS = gql`
  query getQuestsAssignments($first: Int) {
    currentWorkwayQuestAssignments(first: $first) {
      edges {
        node {
          id
          startDate
          endDate
          createdAt
          quest {
            authors {
              name
            }
            description
            url
            slug
            language
            enrollmentStartedAt
            courseStartedAt
            courseEndedAt
            type
            coverAsset {
              thumbnailUrl
              url
            }
            id
            name
            wordmarkAsset {
              url
            }
            pages {
              id
              url
            }
            userProgress {
              resumePage {
                id
                name
                duration
                position
                url
              }
              started
              totalDays
              totalDaysCompleted
              totalLessons
              totalLessonsCompleted
              endedAt
            }
          }
        }
      }
    }
  }
`;

export const RECOMMENDED_B2B_MEDITATIONS_POPULAR_IN_THE_ORG = gql`
  query recommendedB2bMeditationsPopularInTheOrg($first: Int, $orgId: ID!) {
    recommendedB2bMeditationsPopularInTheOrg(first: $first, orgId: $orgId) {
      edges {
        node {
          meditation {
            ... on Resource {
              id
              type
              subtype
              title
              author {
                name
              }
              coverAsset {
                url
              }
              mediaAsset {
                url
                sizeOfFile
                renditions(labels: ["hls", "mp3", "ogg"]) {
                  url
                  filesize
                  contentType
                }
              }
              outcomes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const RECOMMENDED_B2B_SOUNDSCAPE_POPULAR_IN_THE_ORG = gql`
  query recommendedB2bSoundscapesPopularInTheOrg($first: Int, $orgId: ID!) {
    recommendedB2bSoundscapesPopularInTheOrg(first: $first, orgId: $orgId) {
      edges {
        node {
          soundscape {
            ... on Resource {
              id
              type
              subtype
              title
              author {
                name
              }
              coverAsset {
                url
              }
              mediaAsset {
                url
                sizeOfFile
                renditions(labels: ["hls", "mp3", "ogg"]) {
                  url
                  filesize
                  contentType
                }
              }
              trailerLoopingAsset {
                url
                renditions(labels: ["hls", "mp3", "ogg"]) {
                  url
                  filesize
                  contentType
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const QUEST_BY_PROGRESS = gql`
  query getQuestsByProgress(
    $progress: String
    $first: Int
    $type: ResourceTypeFilterEnum!
  ) {
    b2bQuests(progress: $progress, first: $first) {
      edges {
        node {
          id
          name
          description
          coverAsset {
            thumbnailUrl
          }
          authors {
            avatarAsset {
              url
            }
          }
          questResources(type: $type) {
            id
            title
            totalDuration
            coverAsset {
              url
              duration
            }
            author {
              name
            }
            ... on Resource {
              subtype
              mediaAsset {
                url
                sizeOfFile
                renditions(labels: ["hls", "mp3", "ogg"]) {
                  url
                  filesize
                  contentType
                }
              }
              tags {
                name
              }
            }
          }
          headshotCoverAsset {
            url
          }
          wordmarkAsset {
            url
          }
        }
      }
    }
  }
`;

export const GET_RECOMMENDED_HARMONIZED_MEDITATIONS = gql`
  query getRecommendedHarmonizedMeditations(
    $first: Int
    $orgId: ID!
    $limit: String
  ) {
    recommendedB2bHarmonizedMeditation(
      first: $first
      orgId: $orgId
      limit: $limit
    ) {
      edges {
        node {
          soundscape {
            id
            title
            description
            author {
              name
            }
            coverAsset {
              url
            }
            trailerLoopingAsset {
              id
              url
              renditions(labels: ["hls", "mp3", "ogg"]) {
                id
                url
                thumbnailUrl
                contentType
              }
            }
            averageRating
            ratingsCount
            ... on Resource {
              subtype
              mediaAsset {
                contentType
                duration
                url
                thumbnailUrl
                renditions(labels: ["hls", "mp3", "ogg"]) {
                  url
                  filesize
                  contentType
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_COLLECTIONS = gql`
  query getb2bCollections(
    $channelIdentifiers: [String!]!
    $status: SeriesPublishStatusFilterEnum!
    $first: Int
    $limit: Int!
    $resourceFeaturedStatus: ResourceFeaturedStatusFilterEnum!
    $resourceStatus: ResourceStatusFilterEnum!
    $type: ResourceTypeFilterEnum!
    $sortBy: ResourceSortByEnum!
  ) {
    b2bCollections(
      channelIdentifiers: $channelIdentifiers
      first: $first
      status: $status
    ) {
      edges {
        node {
          id
          title
          description
          slug
          landscapeCoverAsset {
            url
          }
          coverAsset {
            url
          }
          collectionResources(
            limit: $limit
            type: $type
            featuredStatus: $resourceFeaturedStatus
            status: $resourceStatus
            sortBy: $sortBy
          ) {
            id
            title
            description
            totalDuration
            author {
              name
            }
            coverAsset {
              url
            }
            trailerLoopingAsset {
              id
              url
              sizeOfFile
              renditions(labels: ["hls", "mp3", "ogg"]) {
                url
                contentType
              }
            }
            ... on Resource {
              mediaAsset {
                id
                duration
                sizeOfFile
                url
                contentType
                renditions(labels: ["hls", "mp3", "ogg"]) {
                  id
                  url
                  filesize
                  contentType
                  duration
                }
              }
              type
              subtype
            }
          }
        }
      }
    }
  }
`;
export const GET_MEDITATION_OF_THE_DAY = gql`
  query recommendedB2bMeditationsOfTheDay($first: Int, $orgId: ID!) {
    recommendedB2bMeditationsOfTheDay(first: $first, orgId: $orgId) {
      edges {
        node {
          meditation {
            id
            title
            description
            author {
              name
            }
            coverAsset {
              url
            }
            landscapeCoverAsset {
              url
            }
            ... on Resource {
              type
              subtype
              mediaAsset {
                id
                duration
                sizeOfFile
                url
                contentType
                renditions(labels: ["hls", "mp3", "ogg"]) {
                  url
                  contentType
                }
              }
              trailerLoopingAsset {
                id
                url
                renditions(labels: ["hls", "mp3", "ogg"]) {
                  url
                  contentType
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_SOUNDSCAPE_OF_THE_WEEK = gql`
  query recommendedB2bSoundscapesOfTheWeek($first: Int, $orgId: ID!) {
    recommendedB2bSoundscapesOfTheWeek(first: $first, orgId: $orgId) {
      edges {
        node {
          soundscape {
            id
            title
            description
            author {
              name
            }
            coverAsset {
              url
            }
            landscapeCoverAsset {
              url
            }
            ... on Resource {
              id
              type
              subtype
              mediaAsset {
                id
                duration
                sizeOfFile
                url
                contentType
                renditions(labels: ["hls", "mp3", "ogg"]) {
                  id
                  url
                  filesize
                  contentType
                  duration
                }
              }
              trailerLoopingAsset {
                id
                url
                sizeOfFile
                renditions(labels: ["hls", "mp3", "ogg"]) {
                  url
                  contentType
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const RESOURCE_FIELDS = gql`
  fragment ResourceFields on Resource {
    id
    type
    title
    description
    author {
      name
    }
    coverAsset {
      name
      url
    }
    averageRating
    ... on Resource {
      mediaAsset {
        url
        duration
        contentType
      }
    }
  }
`;

export const GET_RESOURCES_BY_TYPE = gql`
  query getResourcesByType(
    $language: String!
    $first: Int
    $after: String
    $type: ResourceTypeFilterEnum!
  ) {
    standaloneResources(
      language: $language
      first: $first
      after: $after
      type: $type
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ResourceFields
        }
      }
    }
  }
  ${RESOURCE_FIELDS}
`;

export const GET_RESOURCE_BY_ID = gql`
  query getResourceById($id: ID!) {
    standaloneResource(id: $id) {
      ...ResourceFields
    }
  }
  ${RESOURCE_FIELDS}
`;
