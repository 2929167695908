// @ts-ignore
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./Programs.css";
import useCategories from "hooks/useCategories";
import useLanguages from "hooks/useLanguages";
import useQuest from "hooks/useQuest";
import useScreenType from "hooks/useScreenType";
import { cn } from "libs/classMerger";
import { useLocation, useSearchParams } from "react-router-dom";
import { QuestCategoryEnum } from "types/interfaces";
import type { ICategoryNode } from "types/interfaces";
import { QuestsCategorySection } from "./sections/QuestsCategorySection";
import { ProgramsHeroContainer } from "./components/ProgramsHeroContainer";
import { Button } from "components/Button";
import { LanguageType } from "types/__generated__/graphql";
import { LanguagePicker } from "./components/LanguagePicker";
import { Menu } from "./components/Menu";
import { MenuDrawer } from "components/MenuDrawer";

export default function Programs() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryParam = searchParams.get("category");
  const categoryView = useRef<HTMLDivElement>(null);
  const screenType = useScreenType();

  const [selectedTab, setSelectedTab] = useState<QuestCategoryEnum>(
    QuestCategoryEnum.Career
  );
  const [selectedLang, setSelectedLang] = useState("en");

  const {
    data: nonEnglishQuestsData,
    loading: fetchingNonEnglishQuestsData,
    fetchMoreQuests: fetchMoreNonEnglishQuests,
    pageInfo: nonEnglishPageInfo,
  } = useQuest({
    variables: {
      first: 20,
      category: selectedTab,
      language: selectedLang,
    },
    skippable: selectedLang === "en",
  });

  const {
    data: englishQuestsData,
    loading: fetchingEnglishQuestsData,
    fetchMoreQuests: fetchMoreEnglishQuests,
    pageInfo: englishPageInfo,
  } = useQuest({
    variables: {
      first: 20,
      category: selectedTab,
      language: "en",
    },
  });

  const { categoriesData, loading: categoryDataLoading } = useCategories();
  const { data: languages } = useLanguages({
    first: 20,
    type: LanguageType.Preferred,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const sortedLanguages = useMemo(() => {
    const selected = languages.find((lang) => lang.iso2Code === selectedLang);
    const others = languages.filter((lang) => lang.iso2Code !== selectedLang);
    return selected ? [selected, ...others] : languages;
  }, [selectedLang, languages]);

  const category = useMemo(
    () => categoriesData.find((cat) => cat.id === selectedTab),
    [categoriesData, selectedTab]
  );

  const bannerImage = useMemo(() => {
    return category?.assets?.banners[
      screenType.isDesktop
        ? "desktop"
        : screenType.isMobile
        ? "mobile"
        : "tablet"
    ];
  }, [category, screenType]);

  const onCategorySelect = useCallback(
    debounce((category: ICategoryNode) => {
      setSelectedTab(category.id as QuestCategoryEnum);
      categoryView.current?.scrollIntoView({ behavior: "smooth" });
    }, 300),
    []
  );

  useEffect(() => {
    if (location.state?.subPageId) {
      setSelectedTab(location.state.subPageId as QuestCategoryEnum);
    }
  }, [location]);

  const onLanguageSelect = useCallback(
    debounce((language: string) => {
      setSelectedLang(language);
    }, 300),
    []
  );
  const languageSelected = languages.find(
    (lang) => lang.iso2Code === selectedLang
  );

  useEffect(() => {
    if (categoriesData.length > 0 && categoryParam) {
      const categoryFound = categoriesData.find(
        (cat) => cat.id.toLowerCase() === categoryParam.toLowerCase()
      );
      if (categoryFound) {
        setSelectedTab(categoryFound.id as QuestCategoryEnum);
        setSearchParams({ category: "" });
      }
    }
  }, [categoryParam, categoriesData]);

  const desktopMenu = (
    <div className="flex items-center border-y border-cool-grey-200 border-md gap-9 sticky top-0 bg-white z-40 w-full">
      <div
        className="flex w-full gap-9"
        data-testid="category-sticky-tabs-section"
      >
        {categoriesData?.map((programCategory: ICategoryNode) => (
          <div key={programCategory.id} className="flex flex-col items-center">
            <Button
              key={programCategory.id}
              variant="inherit"
              onClick={() =>
                setSelectedTab(programCategory.id as QuestCategoryEnum)
              }
              data-selected={programCategory.id === category?.id}
              className={cn(
                "flex h-full py-5 px-0 hover:border-b-2 border-cool-grey-300",
                "data-[selected=true]:border-b-2 data-[selected=true]:border-red-300",
                programCategory.name === "Career" && "text-blue-500",
                programCategory.name === "Mindset" && "text-teal-500",
                programCategory.name === "Collaboration" && "text-purple-500",
                programCategory.name === "Soul" && "text-orange-500",
                programCategory.name === "Health" && "text-green-500",
                programCategory.name === "Intrapreneurship" && "text-yellow-500"
              )}
            >
              <span className="heading-8 opacity-80">
                {programCategory.name.toLowerCase()}
              </span>
            </Button>
          </div>
        ))}
      </div>
      <LanguagePicker
        languages={sortedLanguages}
        selectedLang={selectedLang}
        setSelectedLang={setSelectedLang}
      />
    </div>
  );

  const mobileMenu = (
    <aside className="flex lg:hidden py-2 sticky top-0 left-0 bg-white px-3 z-40">
      <div className="w-full rounded-lg border border-md border-cool-grey-200 gap-1 flex">
        <MenuDrawer
          trigger={
            <button
              type="button"
              className="flex flex-col w-1/2 px-3 py-2 items-center"
            >
              <div>
                <h2 className="heading-9 text-cool-grey-400 uppercase">
                  {" "}
                  By Category{" "}
                </h2>
                <h3
                  className={cn(
                    category?.name.toLowerCase(),
                    "heading-8 mt-0.5 lowercase"
                  )}
                >
                  <span>{category?.name}</span>
                </h3>
              </div>
            </button>
          }
        >
          <Menu
            currentOption={{
              name: category?.name ?? "",
              value: category?.id ?? "",
            }}
            title="Category"
            options={categoriesData.map((programCategory: ICategoryNode) => ({
              value: programCategory.id,
              name: programCategory.name.toLowerCase(),
            }))}
            onSelectOption={(option) => {
              const category = categoriesData.find(
                (cat) => cat.id === option.value
              );
              if (category) onCategorySelect(category);
            }}
          />
        </MenuDrawer>
        <MenuDrawer
          trigger={
            <button
              type="button"
              className="relative flex items-center flex-col w-1/2 px-3 py-2 before:inline-block before:h-[calc(100%-16px)] before:bg-black-12a before:w-px before:absolute before:left-0 justify-center"
            >
              <div>
                <h2 className="heading-9 text-cool-grey-400 uppercase">
                  {" "}
                  By Language{" "}
                </h2>
                <h3 className="title-9 text-cool-grey-600 mt-0.5">
                  {" "}
                  {languageSelected?.label}{" "}
                </h3>
              </div>
            </button>
          }
        >
          <Menu
            currentOption={{
              value: languageSelected?.iso2Code ?? "",
              name: languageSelected?.label ?? "",
            }}
            title="Language"
            options={sortedLanguages.map((lang) => ({
              value: lang.iso2Code ?? "",
              name: lang.label ?? "",
            }))}
            onSelectOption={(option) => {
              const languageFound = sortedLanguages.find(
                (lang) => lang.iso2Code === option.value
              );
              if (languageFound) onLanguageSelect(languageFound.iso2Code ?? "");
            }}
          />
        </MenuDrawer>
      </div>
    </aside>
  );

  return (
    <div
      className="flex flex-col pt-4 container px-4 md:px-5 lg:px-5 xl:px-5"
      style={{ maxWidth: "1800px" }}
    >
      <ProgramsHeroContainer
        categoriesData={categoriesData}
        onCategoryClick={onCategorySelect}
        loading={categoryDataLoading}
      />

      {screenType.isDesktop || screenType.isLargeDesktop
        ? desktopMenu
        : mobileMenu}

      <div className="mt-8" ref={categoryView}>
        <QuestsCategorySection
          category={category}
          bannerImage={bannerImage}
          selectedLanguageLabel={
            languages.find((lang) => lang.iso2Code === selectedLang)?.label
          }
          nonEnglishQuestsData={nonEnglishQuestsData}
          englishQuestsData={englishQuestsData}
          loading={fetchingEnglishQuestsData || fetchingNonEnglishQuestsData}
          fetchMoreQuests={
            selectedLang === "en"
              ? fetchMoreEnglishQuests
              : fetchMoreNonEnglishQuests
          }
          pageInfo={
            selectedLang === "en" ? englishPageInfo : nonEnglishPageInfo
          }
        />
      </div>
    </div>
  );
}
