import { Button } from "@fluentui/react-components";
import { app, meeting } from "@microsoft/teams-js";
import { ArrowLeftFluent } from "components/icons/fluent/ArrowLeftFluent";
import useCuratedMeditationSeries from "hooks/useCuratedMeditationSeries";
import { cn } from "libs/classMerger";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import type { PrimaryAssetI } from "types/interfaces";
import { durationToMinutes } from "utils/time";
import { NavigationEnum } from "router/navigationType";

export default function MeditationSeriesDetailsSidePanelView() {
  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();
  const { curatedData } = useCuratedMeditationSeries();
  const [frameContext, setFrameContext] = useState<string | null>(null);

  useEffect(() => {
    app.getContext().then((context: app.Context) => {
      setFrameContext(context.page.frameContext);
    });
  }, []);

  if (!slug) {
    return null;
  }

  // TEMPORARY MOCK FUNCTION
  function getSlugData(slug?: string) {
    return curatedData.find((item) => item.slug === slug);
  }
  const data = getSlugData(slug);
  const duration = durationToMinutes(data?.media[0].mediaAsset?.duration);

  if (!data) {
    return null;
  }

  function shareSpecificPart(mediaUrl: PrimaryAssetI, coverAsset: string) {
    let appContentUrl = "";

    if (frameContext === "content") {
      navigate(
        `${NavigationEnum.StageView}?mediaUrl=${encodeURIComponent(
          mediaUrl.url
        )}&thumbnailUrl=${encodeURIComponent(
          coverAsset
        )}&autoplay=true&mimeType=${
          mediaUrl.contentType
        }&isStageViewSupported=false`
      );
    } else {
      appContentUrl = `${window.location.origin}${
        NavigationEnum.StageView
      }?mediaUrl=${encodeURIComponent(
        mediaUrl.url
      )}&thumbnailUrl=${encodeURIComponent(coverAsset)}&autoplay=true`;
      meeting.shareAppContentToStage((err, result) => {
        if (result) {
          console.log(result);
        }
        if (err) {
          alert(JSON.stringify(err));
        }
      }, appContentUrl);
    }
  }

  return (
    <section>
      <div
        className={cn(
          "bg-cover bg-center bg-no-repeat h-[124px] w-full relative p-3"
        )}
        style={{
          backgroundImage: `url(${data.coverAsset.url})`,
        }}
      >
        <div className="absolute inset-0 bg-black opacity-70" />
        <div className="grid gap-3 relative text-[#F5F5F5]">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="flex items-center space-x-2"
          >
            <ArrowLeftFluent /> <p>Meditation series</p>
          </button>
          <div>
            <p>{data.title}</p>
            <p className="text-[10px] text-[#D1D1D1]">
              {data.media.length} Meditations
            </p>
          </div>
        </div>
      </div>
      <div className="grid gap-6 py-3">
        {data.media.map((meditation) => (
          <div className="flex items-center space-x-3" key={meditation.id}>
            <img
              src={meditation.coverAsset?.url}
              alt=""
              role="presentation"
              className="size-[100px] rounded-lg"
            />
            <div className="flex flex-col gap-y-3">
              <div className="flex flex-col sapce-y-1">
                <h3 className="text-[14px] font-semibold">
                  {meditation.title}
                </h3>
                <div className="flex items-center space-x-2 text-[10px] text-[#616161]">
                  <p>{meditation.author.name}</p>
                  <div>•</div>
                  <p>{duration}</p>
                </div>
              </div>

              <div className="flex space-x-1">
                <Button
                  size="small"
                  onClick={() => {
                    shareSpecificPart(
                      meditation.mediaAsset,
                      meditation.coverAsset?.url ?? ""
                    );
                  }}
                >
                  {frameContext === "content" ? " Listen" : " Listen together"}
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
