import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useToast } from "hooks/use-toast";
import LockedModal from "components/LockedModal";
import { CenteredOverlaySpinner } from "components/Spinner";
import Icons from "components/icons/Icons";
import { QuestConsumptionSkeleton } from "components/loading/QuestConsumptionSkeleton";
import { useUserState } from "context/userContext";
import { QuestLandingPage } from "../QuestLanding/QuestLandingPage";
import { QuestConsumptionFooter } from "./QuestConsumptionFooter";
import { MeditationModal } from "components/VideoPlayer/MeditationModal";
import QuestDetails from "./QuestDetails";
import QuestSideBar from "./QuestSideBar";
import { getLessonsStatus } from "./utils";
import {
  useGetQuestPageByIdQuery,
  useMarkPageAsCompletedMutation,
  type B2bQuest,
  type Page,
  type UserProgress,
  type Media,
} from "types/__generated__/graphql";
import MVUniversalPlayerReact from "components/VideoPlayer/MVUniversalPlayerReact";

const QuestConsumption = () => {
  const navigate = useNavigate();
  const { slug, lang } = useParams();
  const [searchParams] = useSearchParams();
  const pageId = searchParams.get("pageId");
  const urlPath = useLocation().pathname;
  const { toast } = useToast();

  // STATE
  const { userAccessState } = useUserState();
  const [currentPage, setCurrentPage] = useState<Page>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMediationModalOpen, setIsMediationModalOpen] = useState(false);
  const [currentResource, setCurrentResource] = useState<Media | null>(null);

  const {
    data: pageRaw,
    loading: pageLoading,
    refetch: refetchPage,
  } = useGetQuestPageByIdQuery({
    variables: {
      id: pageId || "",
    },
    fetchPolicy: "cache-and-network",
  });

  const [
    markPageAsCompleted,
    { loading: markPageLoading, error: markPageError },
  ] = useMarkPageAsCompletedMutation();

  const page = pageRaw?.page as Page;

  const mediaUrl = page?.sections?.find((s) => s.type === "video")?.primaryAsset
    ?.renditions;
  const markers = page?.sections?.find((s) => s.type === "video")?.primaryAsset
    ?.markers;
  const duration =
    page?.sections?.find((s) => s.type === "video")?.primaryAsset?.duration ||
    0;
  const author = page?.quest?.authors?.[0]?.name || "";

  const { total } = getLessonsStatus(
    page?.quest?.type || "",
    page?.quest?.userProgress as UserProgress
  );

  const mediaData = {
    id: page?.id || "",
    mediaAsset: {
      renditions: mediaUrl,
      duration: duration,
      markers: [...(markers || [])],
    },
    coverAsset: { url: page?.coverAsset?.url || "" },
    title: page?.name || "",
    author: author,
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  function handleStartProgram() {
    setIsModalOpen(true);
  }

  function handleLessonSelect(selectedPage: Page) {
    refetchPage({
      id: selectedPage.id,
    });
  }

  async function handleMarkLessonAsComplete(page?: Page) {
    if (!page) return;
    console.log("pagess", page);
    const pageId = page.id || "";
    try {
      await markPageAsCompleted({ variables: { input: { pageId } } });

      if (!page.nextPage) {
        toast({
          description: "You have completed the quest!",
        });
        return navigate(`/quests/${slug}/${lang}`);
      }

      await refetchPage({
        id: page.nextPage?.id || "",
      });
    } catch {
      toast({
        variant: "destructive",
        description: "Failed to mark page as completed. Please try again!",
      });
    }
  }

  if (pageLoading || !page) return <QuestConsumptionSkeleton />;

  console.log("page", page);
  return (
    <div className="bg-white flex flex-col h-screen lg:overflow-auto w-full">
      {/* <CenteredOverlaySpinner show={enrollLoading || questLoading} /> */}
      <div className="flex-1 flex flex-col lg:flex-row overflow-y-auto w-full">
        <main className="lg:h-[calc(100dvh-20px)] flex flex-col lg:flex-1 lg:overflow-y-auto no-scrollbar sticky lg:static top-0 z-10 touch-auto">
          <div className="lg:hidden px-4 py-3 flex items-center justify-between bg-white">
            <div>
              <div className="title-8 text-cool-grey-700">
                {page?.quest?.name}
              </div>
              <div className="title-10 text-cool-grey-500 pt-0.5 capitalize">
                lesson {currentPage?.position} of {total}
              </div>
            </div>
            <QuestLandingPage
              questArgs={page?.quest as B2bQuest}
              trigger={
                <button type="button">
                  <Icons name="more-vertical-outlined" />
                </button>
              }
            />
          </div>
          <div className="flex flex-1 lg:flex-none w-full">
            <div className="relative size-full">
              {mediaUrl ? (
                <MVUniversalPlayerReact
                  key={page.id}
                  media={mediaData}
                  muted={false}
                  autoplay={false}
                />
              ) : (
                <div
                  className="size-full aspect-video bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${page?.quest?.coverAsset?.url})`,
                  }}
                />
              )}
            </div>
          </div>

          <div className="hidden lg:block m-6 ml-10">
            <QuestDetails
              page={page}
              onMarkLessonAsComplete={handleMarkLessonAsComplete}
              onStartProgram={() => console.log("Start Program")}
            />
          </div>
        </main>
        <div className="flex h-full overflow-y-auto">
          <QuestSideBar
            page={page}
            onLessonSelect={handleLessonSelect}
            onStartProgram={handleStartProgram}
            onMarkTaskAsCompleted={() => console.log("")}
            handleMeditationResourcePlay={(resource: Media) => {
              console.log("resource", resource);
              setCurrentResource(resource);
              setIsMediationModalOpen(true);
            }}
          />
        </div>
        <QuestConsumptionFooter
          page={page}
          onMarkPageAsCompleted={handleMarkLessonAsComplete}
        />
        <LockedModal
          onCancel={() => setIsModalOpen(false)}
          open={isModalOpen}
          userAccessState={userAccessState}
        />
        <MeditationModal
          onClose={() => setIsMediationModalOpen(false)}
          open={isMediationModalOpen}
          media={currentResource as Media}
        />
      </div>
    </div>
  );
};

export default QuestConsumption;
