import { Button } from "components/Button";
import Icons from "components/icons/Icons";
import { WorkwayLogoFull } from "components/logo/WorkwayLogo";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import useUserProfile from "hooks/useUserProfile";
import axios from "axios";

interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  country: string;
  phoneNumber: string;
  notes: string;
}

// Define the form field configuration
const fields = [
  {
    name: "email",
    label: "Work email",
    type: "email",
    placeholder: "Enter your email",
    required: true,
    pattern: /^\S+@\S+$/i,
    errorMessage: "Invalid email address",
  },
  {
    name: "firstName",
    label: "First name",
    type: "text",
    placeholder: "Enter your first name",
    required: true,
  },
  {
    name: "lastName",
    label: "Last name",
    type: "text",
    placeholder: "Enter your last name",
    required: true,
  },
  {
    name: "companyName",
    label: "Company name",
    type: "text",
    placeholder: "Enter your company name",
    required: true,
  },
  {
    name: "country",
    label: "Country / Region",
    type: "text",
    placeholder: "Enter your country or region",
  },
  {
    name: "phoneNumber",
    label: "Phone number",
    type: "tel",
    placeholder: "Enter your phone number",
    pattern: /^[0-9]+$/,
    errorMessage: "Only numbers are allowed",
  },
  {
    name: "notes",
    label: "Notes",
    type: "textarea",
    placeholder: "Please share any details...",
  },
];

const portalId = "619497";
const formId = "90d588ec-8aa8-4359-b2fc-33e6a08ebc7f";
const InterestForm = () => {
  const navigate = useNavigate();
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const { data, loading, error } = useUserProfile();
  const currentYear = new Date().getFullYear();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      companyName: "",
      country: "",
      phoneNumber: "",
      notes: "",
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    const hubspotUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

    const response = await axios.post(hubspotUrl, {
      fields: [
        {
          name: "email",
          value: formData.email,
        },
        {
          name: "firstname",
          value: formData.firstName,
        },
        {
          name: "lastname",
          value: formData.lastName,
        },
        {
          name: "0-2/name",
          value: formData.companyName,
        },
        {
          name: "country",
          value: formData.country,
        },
        {
          name: "phone",
          value: formData.phoneNumber,
        },
        {
          name: "notes",
          value: formData.notes,
        },
      ],
      "context": {
        "pageUri": "https://teams.microsoft.com/v2/",
        "pageName": "Workway-MS-Teams"
      }
    });
    if (response.status === 200) {
      navigate("/thankyouScreen");
    }
  };

  useEffect(() => {
    if (data?.currentProfile) {
      setValue("email", data.currentProfile.email);
      setValue("firstName", data.currentProfile.firstName);
      setValue("lastName", data.currentProfile.lastName);
    }
  }, [data, setValue]);

  return (
    <div className="flex flex-col gap-y-6 pt-2 px-4 md:px-5 lg:px-5 xl:px-5 container" style={{ maxWidth: "1800px" }}>
      <div>
        <Button
          variant={"icon"}
          className="px-0 py-[6px]"
          onClick={() => {
            navigate(-1);
          }}
        >
          <Icons
            name="arrow-left-circle-outlined"
            width={20}
            height={20}
            fill="black"
          />
          <p className="px-1 button-text-medium">Back</p>
        </Button>
      </div>
      <div className="max-w-[680px] self-center">
        <div className="  flex flex-col items-center gap-y-4">
          <WorkwayLogoFull width="160" height="26" />
          <p className={`title-8 ${isFormSubmitted ? "hidden" : "block"}`}>
            Kindly fill out this form and we will get back to you.
          </p>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center sm:mx-auto max-w-[600px]">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white shadow-light rounded-[16px] sm:p-10 p-4 w-full flex flex-col gap-6 "
        >
          <h2 className="text-xl font-semibold">
            Fill out your company details
          </h2>

          {/* Map through the fields array to render inputs */}
          {fields.map((field) => (
            <div key={field.name}>
              <label className="title-8">
                {field.label}{" "}
                {field.required && <span className="text-red-500">*</span>}
              </label>

              {field.type === "textarea" ? (
                <textarea
                  {...register(field.name as keyof FormValues, {
                    required: field.required
                      ? `${field.label} is required`
                      : false,
                    pattern: field.pattern
                      ? { value: field.pattern, message: field.errorMessage }
                      : undefined,
                  })}
                  className="w-full  focus:ring-0 focus:outline-none focus:border-brown-500 p-2 border border-brown-400 rounded-md"
                  placeholder={field.placeholder}
                ></textarea>
              ) : (
                <input
                  type={field.type}
                  {...register(field.name as keyof FormValues, {
                    required: field.required
                      ? `${field.label} is required`
                      : false,
                    pattern: field.pattern
                      ? { value: field.pattern, message: field.errorMessage }
                      : undefined,
                  })}
                  className="w-full h-[40px] focus:ring-0 focus:outline-none focus:border-brown-500 p-2 border border-brown-400 rounded-md"
                  placeholder={field.placeholder}
                />
              )}

              {/* Error message */}
              {errors[field.name as keyof FormValues] && (
                <p className="text-red-500 caption-disclaimer">
                  {errors[field.name as keyof FormValues]?.message}
                </p>
              )}
            </div>
          ))}

          {/* Terms and Submit Button */}
          <p className="text-sm text-gray-500">
            By submitting the form, you agree to our{" "}
            <a href="https://www.getworkway.com/terms-of-use" target="_blank" className="text-blue-500">
              Terms of use
            </a>{" "}
            and{" "}
            <a href="https://www.getworkway.com/privacy-policy" target="_blank" className="text-blue-500">
              Privacy policy
            </a>
            . We may use the info you submit to contact you and to personalize
            your experience.
          </p>

          <button
            type="submit"
            className="w-fit px-4 py-3 text-white bg-red-600 rounded-[100px] button-text-medium"
          >
            Submit
          </button>
        </form>
      </div>

      <p className="text-center caption-disclaimer text-[#979CA5] mb-6">
        © {currentYear} Workway by Mindvalley
      </p>
    </div>
  );
};

export default InterestForm;
