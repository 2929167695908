import { Button } from "components/Button";
import LockedModal from "components/LockedModal";
import { MeditationModal } from "components/VideoPlayer/MeditationModal";
import Icons from "components/icons/Icons";
import { useUserState } from "context/userContext";
import { cn } from "libs/classMerger";
import { useEffect, useState } from "react";
import useQuestMutations from "hooks/useQuestMutations";
import { StartProgramModal } from "tabs/Quest/QuestLanding/StartProgramModal";
import { convertSecondsToReadableFormat } from "utils/time";
import type { B2bQuest, Resource } from "types/__generated__/graphql";

const ResourceStyle = ({
  resource,
  onResourceClick,
}: {
  resource: Resource;
  onResourceClick: () => void;
}) => {
  const getImageDimension = () => {
    switch (resource.type) {
      case "file":
      case "course":
        return "w-[72px] h-[108] md:w-[120px] md:h-[180px]";
      case "video":
        return "w-[128px] h-[72px] md:w-[212px] md:h-[120px]";
      default:
        return "md:max-w-fit md:h-[120px]";
    }
  };

  return (
    <div
      className="md:mx-4 md:my-1 p-4 gap-4 lg:gap-6 flex items-center cursor-pointer rounded-2xl hover:bg-brown-50"
      onClick={onResourceClick}
      onKeyDown={() => {}}
    >
      <div className="flex min-w-fit">
        {resource.type === "video" && (
          <span className={"relative"} style={{ zIndex: 9 }}>
            <Icons
              name="play-circle-filled"
              fill={"white"}
              className={"absolute bottom-2 left-2 z-10 h-6 w-6 md:h-9 md:w-9"}
            />
          </span>
        )}
        <img
          className={`rounded-lg ${getImageDimension()} aspect-square transition-all ease-in-out duration-500 hover:scale-105 object-cover`}
          src={resource.coverAsset?.url}
          alt={resource.title}
        />
      </div>
      <div className="space-y-4 w-full">
        <div className="space-y-0.5 w-full">
          <p className="title-7 text-cool-grey-700">{resource.title}</p>
          <p className="title-8 text-cool-grey-600">{resource.author?.name}</p>
          <p className="caption-disclaimer text-cool-grey-500">
            {convertSecondsToReadableFormat(resource.totalDuration)}
          </p>
        </div>
        <p className="hidden body-small text-cool-grey-600 overflow-hidden lg:line-clamp-3 lg:data-[long]:line-clamp-4 text-ellipsis">
          {resource.description}
        </p>
        {resource.type === "file" && (
          <Button
            onClick={() => {
              if (resource.mediaAsset?.url) {
                window.location.href = resource.mediaAsset.url;
              }
            }}
            variant="link"
            className="p-0 h-fit"
          >
            Download
          </Button>
        )}
        {resource.type === "course" && (
          <Button onClick={() => {}} variant="link" className="p-0 h-fit">
            View course
          </Button>
        )}
      </div>
      {
        //TODO update with appropriate condition to show the lock icon
        // resource.type !== "file" && (
        //   <Icons name="lock-filled" fill="#595E67" height={24} width={24} />
        // )
      }
    </div>
  );
};

export const Resources = (quest: B2bQuest) => {
  type ResourceDetail = {
    type: string;
    resources: Resource[];
    position: number;
  };

  const { handleEnrollUser } = useQuestMutations();
  const [resourceDetails, setResourceDetails] = useState<ResourceDetail[]>([]);
  const [collapsedSections, setCollapsedSections] = useState<{
    [key: string]: boolean;
  }>({});
  const [showModal, setShowModal] = useState(false);
  const [isLockedModalOpen, setIsLockedModalOpen] = useState(false);
  const [isMediationModalOpen, setIsMediationModalOpen] = useState(false);
  const [currentResource, setCurrentResource] = useState<Resource | null>(null);
  const handleOnClose = () => setShowModal(false);
  const { userAccessState } = useUserState();

  useEffect(() => {
    const groupedResources = groupResourcesByType(quest.resources ?? []);
    setResourceDetails(groupedResources);
    const initialCollapsedState = groupedResources.reduce((acc, item) => {
      acc[item.type] = false;
      return acc;
    }, {} as { [key: string]: boolean });
    setCollapsedSections(initialCollapsedState);
  }, [quest]);

  const getPosition = (type: string) => {
    switch (type) {
      case "meditation":
        return 1;
      case "audio":
        return 2;
      case "file":
        return 3;
      case "video":
        return 4;
      case "course":
        return 5;
      default:
        return 0;
    }
  };
  const groupResourcesByType = (resources: Resource[]): ResourceDetail[] =>
    resources
      .reduce((acc: ResourceDetail[], resource) => {
        const existingResourceDetail = acc.find(
          (detail) => detail.type === resource.type
        );
        if (existingResourceDetail) {
          existingResourceDetail.resources.push(resource);
        } else {
          acc.push({
            position: getPosition(resource.type ?? ""),
            type: resource.type ?? "",
            resources: [resource],
          });
        }
        return acc;
      }, [])
      .sort((a, b) => a.position - b.position);

  const toggleSection = (section: string) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };
  const getResourceType = (type: string) => {
    switch (type) {
      case "file":
        return "PDFs";
      case "course":
        return "Courses";
      case "video":
        return "Videos";
      case "audio":
        return "Audios";
      case "meditation":
        return "Meditations";
    }
  };

  const handleStartProgram = async () => {
    const releaseId = quest?.releases?.[0]?.id;
    if (!releaseId) return;

    try {
      await handleEnrollUser(releaseId);
      setShowModal(false);
    } catch (error) {
      if (error === "unauthorized") {
        setIsLockedModalOpen(true);
      }
    }
  };

  // const mediaData = {
  //   id: q
  // };

  return (
    <div className="flex flex-col min-h-[100%]">
      {resourceDetails.map((item) => {
        if (item.type !== "course")
          return (
            <div key={item.type} className="flex flex-col mb-8">
              <button
                onClick={() => toggleSection(item.type)}
                type="button"
                className="flex justify-start items-center cursor-pointer flex-row-reverse lg:flex-row px-4 md:px-9 lg:px-10 py-4 border-b border-black-12a gap-4"
              >
                <div
                  className={cn(
                    "transition-transform duration-200",
                    collapsedSections[item.type] ? "" : "rotate-180"
                  )}
                >
                  <Icons
                    name="chevron-down-filled"
                    fill="#71767F"
                    height={24}
                    width={24}
                  />
                </div>
                <div className="flex flex-col gap-y-0.5 flex-1 text-left">
                  <h4 className="heading-6 text-cool-grey-600">
                    {getResourceType(item.type)}
                  </h4>
                </div>
              </button>
              <div
                className={cn(
                  "flex flex-col transition-all duration-300",
                  collapsedSections[item.type] ? "hidden" : "block"
                )}
              >
                {item.resources.map((contentItem) => (
                  <div key={contentItem?.id}>
                    <ResourceStyle
                      resource={contentItem}
                      onResourceClick={() => {
                        //TODO update with appropriate condition to show the popup
                        // if (contentItem.type !== "file") setShowModal(true);
                        console.log(contentItem);
                        if (contentItem.type === "meditation") {
                          setIsMediationModalOpen(true);
                          setCurrentResource(contentItem);
                        }
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          );
      })}
      {showModal && (
        <StartProgramModal
          quest={quest}
          onClose={handleOnClose}
          handleStartProgram={handleStartProgram}
        />
      )}
      <LockedModal
        onCancel={() => setIsLockedModalOpen(false)}
        open={isLockedModalOpen}
        userAccessState={userAccessState}
      />
      <MeditationModal
        onClose={() => setIsMediationModalOpen(false)}
        open={isMediationModalOpen}
        media={currentResource ?? undefined}
      />
    </div>
  );
};
