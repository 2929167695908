import {
  FluentProvider,
  type Theme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  teamsLightTheme,
  tokens,
} from "@fluentui/react-components";
import StickyBar from "components/StickyBar";
import { UserAccessStateEnum, useUserState } from "context/userContext";
import { cn } from "libs/classMerger";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Toaster } from "../components/Toaster";
import Footer from "./Footer";
import Navbar from "./Navbar";
import MediaPlayerV2 from "../components/media-player/MediaPlayerV2";
import MediaPlayerTimerManager from "components/media-player/MediaPlayerTimerManager";
import { MediaPlayerRefsProvider } from "context/MediaPlayerRefsContext";
import { NavigationEnum } from "router/navigationType";
import MediaPlayerV2AudioController from "components/media-player/MediaPlayerV2AudioController";
// import { useApolloClient } from "@apollo/client";

export default function Layout({
  children,
  themeString,
}: {
  children: React.ReactNode;
  themeString: string;
  theme?: Theme;
}) {
  const location = useLocation();
  const { userAccessState } = useUserState();
  const isSidePanelView =
    location.pathname.startsWith(NavigationEnum.AppInMeeting) ||
    location.pathname.startsWith(NavigationEnum.Configure);
  const isQuestsPath = location.pathname.startsWith(NavigationEnum.Quests);
  const isStageView = location.pathname.startsWith(NavigationEnum.StageView);
  const isInterestForm = location.pathname === NavigationEnum.InterestForm;

  const isPresentingForm =
    location.pathname === NavigationEnum.CompanyForm ||
    location.pathname === NavigationEnum.InterestForm ||
    location.pathname === NavigationEnum.ThankyouScreen;

  const currentTheme = useMemo(() => {
    if (themeString === "dark" && isSidePanelView) return teamsDarkTheme;
    if (themeString === "contrast") return teamsHighContrastTheme;

    return {
      ...teamsLightTheme,
      colorNeutralBackground3:
        isSidePanelView || isStageView ? "transparent" : "#ffffff",
    };
  }, [themeString, isSidePanelView, isStageView]);

  // Since  we cant use apollo debugger in inframe, we can use this to get the cache data
  // const client = useApolloClient();
  // const cacheData = client.cache.extract();
  // console.log(cacheData);

  return (
    <FluentProvider
      theme={currentTheme}
      style={{ background: tokens.colorNeutralBackground3 }}
    >
      <MediaPlayerRefsProvider>
        <Toaster />
        {!isPresentingForm && <Navbar />}
        <div
          className={cn(
            isSidePanelView && "pb-11",
            isInterestForm && "bg-brown-50"
          )}
        >
          <div
            className={cn(
              "grow",
              !isPresentingForm && "py-16",
              isStageView && "flex items-center justify-center w-full"
            )}
          >
            {children}
          </div>
        </div>
        <Footer
          showFooter={
            !isSidePanelView &&
            !isStageView &&
            !isQuestsPath &&
            !isPresentingForm
          }
          showStickyBar={
            !isPresentingForm &&
            userAccessState !== UserAccessStateEnum.Premium &&
            userAccessState !== UserAccessStateEnum.Freemium
          }
        />
        <MediaPlayerV2AudioController />
        <MediaPlayerV2 />

        <MediaPlayerTimerManager />
      </MediaPlayerRefsProvider>
    </FluentProvider>
  );
}
