// src/components/VideoPlayer/MVUniversalPlayerWrapper.js
import { h, defineComponent } from "vue";
import MVUniversalPlayer, {
  MVAudioPlayer,
  MVAudioResource,
  MVAudioDescription,
} from "@mindvalley/mv-universal-player";

// TEMPORARY FIX TO MAP THE MIME TYPES
const ALLOWED_MIME_TYPES = [
  "application/x-mpegURL",
  "video/mp4",
  "audio/mpeg",
  "audio/mp3",
  "audio/ogg",
];

export default defineComponent({
  name: "MVUniversalAudioPlayerWrapper",
  components: {
    MVUniversalPlayer,
    MVAudioPlayer,
    MVAudioResource,
    MVAudioDescription,
  },
  props: {
    media: {
      type: Object,
      required: true,
    },
    muted: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // formatSources(localSources = []) {
    //   return (localSources || [])
    //     .filter((source: any) =>
    //       ALLOWED_MIME_TYPES.includes(source.contentType)
    //     )
    //     .map((source: any) => ({
    //       type: source.contentType,
    //       src: source.url,
    //     }));
    // },
  },
  render() {
    return h(
      "div",
      {
        class:
          "mv-universal-player  max-w-[1040px] my-10 mx-auto inset-0 z-15 relative overflow-hidden p-6 lg:p-8 rounded-3xl bg-cover bg-center",
        style: {
          backgroundImage: `url(${this.media.posterUrl})`,
        },
      },
      [
        h(MVAudioPlayer, {}, [
          h(MVAudioResource, {
            key: this.media.id,
            assetId: this.media.id,
            sources: this.media.mediaAsset,
            duration: this.media.duration,
            posterUrl: this.media.posterUrl,
            title: this.media.title,
            artistId: this.media.artistId,
            artistName: this.media.artistName,
            authorImage: this.media.authorImage,
            headline: this.media.headline,
            ratings: this.media.ratings,
            totalRatings: this.media.totalRatings,
            description: this.media.description,
            blurEffect: true,
            overlay: true,
            showFavourite: true,
            fullscreen: true,
          }),
          h(MVAudioDescription, {
            imageSrc: this.media.authorImage,
            name: this.media.artistName,
            headline: this.media.headline,
            description: this.media.description,
            showMoreText: "Show More",
            showLessText: "Show Less",
            class: "pt-8",
          }),
          // h("template", { slot: "meditation-mixer" }, [
          //   h("div", { class: "text-cool-grey-350 mb-2 text-xs" }, "Mix Track"),
          //   h("div", { class: "gap-x-2 px-6" }, [
          //     h("MVCarousel", { tagName: "Slide" }, [
          //       h("MVCarouselSlide", { key: 0 }, [
          //         h("MVMeditationTrackItem", { volume: 0 }),
          //       ]),
          //       h(
          //         "MVCarouselSlide",
          //         {},
          //         this.media?.backgroundSounds?.map(
          //           (sound: any, index: number) => {
          //             return h("MVMeditationTrackItem", {
          //               key: index + 1,
          //               sources: sound.item.mediaAsset,
          //               backgroundSrc: sound.item.coverAsset.thumbnailUrl,
          //             });
          //           }
          //         )
          //       ),
          //     ]),
          //   ]),
          //   h(
          //     "div",
          //     {
          //       class:
          //         "flex w-full mt-4 items-center justify-center transition duration-300 ease-in",
          //     },
          //     [
          //       h("MVMeditationVolumeSlider", {
          //         leftText: "sound",
          //         rightText: "vocal",
          //       }),
          //     ]
          //   ),
          // ]),
        ]),
      ]
    );
  },
});
