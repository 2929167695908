import { useState } from "react";
import {
  CarouselPrevious,
  CarouselNext,
  Carousel,
  CarouselContent,
  CarouselItem,
  type CarouselApi,
} from "components/Carousel";
import { Button } from "components/Button";
import { cn } from "libs/classMerger";
import useScreenType from "hooks/useScreenType";
import type { B2bChannel } from "types/__generated__/graphql";

export const CategoriesSection = ({
  data,
  loading,
  error,
  handleSeeAllNavigation,
  handleCategoryNavigation,
}: {
  data: B2bChannel | null;
  loading: boolean;
  error: Error | undefined;
  handleSeeAllNavigation: () => void;
  handleCategoryNavigation: (slug?: string) => void;
}) => {
  const [carouselApi, setCarouselApi] = useState<CarouselApi>();
  const [currentItem, setCurrentItem] = useState<number>(0);
  const screenType = useScreenType();

  function nextPreviousArrowHandler(value: number) {
    const newIndex = Math.max(
      0,
      Math.min(currentItem + value, (data?.collections?.length ?? 0) - 1)
    ); // Ensure index stays within bounds

    if (value > 0 && newIndex > currentItem) {
      carouselApi?.scrollNext();
    } else if (value < 0 && newIndex < currentItem) {
      carouselApi?.scrollPrev();
    }

    setCurrentItem(newIndex);
  }

  if (!data || loading || error) return null;

  return (
    <section className="bg-teal-50">
      <div className="flex flex-col gap-y-0 w-full px-4 md:px-5 lg:px-5 xl:px-5 py-6 gap-6 container" style={{ maxWidth: "1800px" }}>
        <div
          className={cn(
            "flex gap-10 w-full flex-col relative justify-between",
            (screenType.isDesktop || screenType.isLargeDesktop) && "flex-row"
          )}
        >
          <div className="flex flex-col gap-4 w-full lg:max-w-[440px] shrink-0">
            <div className="heading-3">{data.title}</div>
            <div className="body">{data.description}</div>
          </div>
          <div className="flex flex-col gap-4 w-full lg:w-[800px] xl:w-[1069px] relative">
            <div className="flex gap-2 items-center justify-end">
              <Button variant="ghost" onClick={handleSeeAllNavigation}>
                See all
              </Button>
              <div className="hidden md:flex items-start space-x-2 ">
                <CarouselPrevious
                  disabled={currentItem === 0}
                  onClick={() => {
                    nextPreviousArrowHandler(-1);
                  }}
                />
                <CarouselNext
                  disabled={currentItem === (data.collections?.length ?? 0) - 1}
                  onClick={() => {
                    nextPreviousArrowHandler(1);
                  }}
                />
              </div>
            </div>
            <div className="flex w-full overflow-hidden">
              <Carousel
                opts={{
                  align: "start",
                }}
                orientation="horizontal"
                className="w-full"
                setApi={setCarouselApi}
              >
                <CarouselContent className="gap-2 md:gap-4 ml-0">
                  {data.collections?.map((category) => (
                    <CarouselItem
                      key={category?.id}
                      className="basis-auto pl-0 justify-center"
                    >
                      {category && (
                        <div
                          onClick={() => {
                            handleCategoryNavigation(category.slug);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              e.preventDefault();
                              handleCategoryNavigation(category.slug);
                            }
                          }}
                          className="w-[195px] h-[261px] md:w-[188px] md:h-[251px] rounded-2xl overflow-hidden relative cursor-pointer"
                        >
                          <img
                            className="size-full object-cover rounded-2xl transition-all ease-in-out duration-500 hover:scale-105 "
                            src={category.landscapeCoverAsset?.url ?? ""}
                            alt={category.title ?? ""}
                          />
                          <div className="w-full absolute top-0 flex p-3 heading-8 text-white justify-center text-center">
                            {category.title}
                          </div>
                        </div>
                      )}
                    </CarouselItem>
                  ))}
                </CarouselContent>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
