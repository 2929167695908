import Icons from "components/icons/Icons";
import StickyBar from "components/StickyBar";
import { cn } from "libs/classMerger";

const SocialsMedia = [
  {
    link: "https://www.facebook.com/mindvalley",
    icon: <Icons name="facebook" width={16} height={17} fill="#71767F" />,
  },
  {
    link: "https://twitter.com/mindvalley",
    icon: <Icons name="twitter" width={16} height={17} fill="#71767F" />,
  },
  {
    link: "https://www.instagram.com/mindvalley",
    icon: <Icons name="instagram" width={16} height={17} fill="#71767F" />,
  },

  {
    link: "https://www.youtube.com/user/awesomenessfest",
    icon: <Icons name="youtube" width={16} height={17} fill="#71767F" />,
  },
  {
    link: "https://www.linkedin.com/company/getworkway/",
    icon: <Icons name="linkedin" width={16} height={17} fill="#71767F" />,
  },
];

export default function Footer({ showFooter, showStickyBar }: { showFooter: boolean, showStickyBar: boolean }) {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      {showFooter && <div
        className={cn("flex flex-col md:flex-row items-start md:items-center justify-between border-t border-cool-grey-250 w-full px-6",
          showStickyBar && "my-10"
        )}>
        <div className="flex items-center space-x-3 caption-disclaimer py-6 mt-2">
          <div>© {currentYear} Workway by Mindvalley</div>
          <div>•</div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.getworkway.com/terms-of-use"
          >
            Terms
          </a>
          <div>•</div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.getworkway.com/privacy-policy"
          >
            Privacy
          </a>
        </div>

        <div className="flex items-center space-x-6">
          {/* <div className="caption-disclaimer">English (EN)</div> */}
          <div className="flex items-center space-x-4">
            {SocialsMedia.map((social) => {
              return (
                <a
                  key={social.link}
                  href={social.link}
                  target="_blank"
                  rel="noreferrer"
                  className="text-cool-grey-700 hover:text-cool-grey-600"
                >
                  {social.icon}
                </a>
              );
            })}
          </div>
        </div>
      </div>
      }
      {showStickyBar &&
        <div className="fixed bottom-0">
          <StickyBar />
        </div>
      }

    </footer>
  );
}
