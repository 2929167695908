import { CollectionDetailModal } from "components/soundscapesAndMeditations/CollectionDetailModal";
import { Button } from "components/Button";
import Icons from "components/icons/Icons";
import type { B2bChannel } from "types/__generated__/graphql";

export const ViewCollectionButton = ({
  type,
  data,
  navigate,
}: {
  type: "meditations" | "soundscapes";
  data: B2bChannel;
  navigate: () => void;
}) => {
  return (
    <div className="flex gap-1">
      <CollectionDetailModal
        type={type}
        collection={data?.collections?.[0]}
        trigger={
          <Button className="w-full md:w-auto">
            <Icons name="list-filled" width={20} height={20} fill="black" />
            <p className="px-[6px]">View collection</p>
          </Button>
        }
      />
      <Button
        variant="ghost"
        size="roundedIcon"
        onClick={navigate}
        className="bg-brown-12a hover:bg-brown-24a text-white w-10 h-10"
      >
        <Icons name="arrow-right-filled" width={20} height={20} fill="white" />
      </Button>
    </div>
  );
};
