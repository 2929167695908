import { Pencil1Icon } from "@radix-ui/react-icons";
import { Button } from "components/Button";

import Icons from "components/icons/Icons";
import ProfileContentSkeleton from "components/loading/ProfileContentSkeleton";
import useCityList from "hooks/useCityList";
import { useEffect, useState } from "react";
import type { ICity, ICurrentProfile } from "types/interfaces";

export const ProfilePage = ({
  onEdit,
  profileData,
  loading,
}: {
  onEdit: () => void;
  profileData?: ICurrentProfile;
  loading?: boolean;
}) => {
  const { cityData, loading: loadingCities, error: getCitiesError } = useCityList();
  const [currentLocation, setCurrentLocation] = useState<ICity>();

  useEffect(() => {
    if (!cityData) return;
    const foundCity = cityData.find((city) => city.cityCode === profileData?.location);
    if (foundCity) setCurrentLocation(foundCity);
  }, [cityData]);

  if (loading) return <ProfileContentSkeleton />;

  return (
    <div className="flex flex-col gap-8 self-stretch">
      <div className="hidden lg:flex justify-between">
        <div className="heading-5">My profile</div>
        <Button
          className="gap-2 w-full md:w-auto"
          variant={"primary"}
          onClick={onEdit}
        >
          <Pencil1Icon /> <span className="button-text-medium">Edit profile</span>
        </Button>
      </div>

      <div className="flex flex-col md:flex-row gap-6 self-stretch">
        <div className="flex w-full lg:w-1/2 flex-col gap-2">
          <div className="flex gap-2">
            <Icons name="mail-outlined" width={22} height={18} fill="#292D38" />
            <div className="heading-7">Email</div>
          </div>
          <div className="body text-cool-grey-600 [overflow-wrap:anywhere]">{profileData?.email}</div>
        </div>
        <div className="flex w-full lg:w-1/2 flex-col gap-2">
          <div className="flex gap-2">
            <Icons name="map-pin-outlined" width={20} height={24} fill="#292D38" />
            <div className="heading-7">Location</div>
          </div>
          <div className="body">
            {profileData?.location ? (
              `Based in ${profileData?.location}`
            ) : (
              <p className="body text-cool-grey-400">Edit your profile to show</p>
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-6">
        <div className="flex w-full lg:w-1/2 flex-col gap-2">
          <div className="flex gap-2">
            <Icons name="message-circle-outlined" height={20} width={20} fill="#292D38" />
            <div className="heading-7">Languages</div>
          </div>
          <div className="body">
            {profileData?.spokenLanguages && profileData.spokenLanguages.length > 0 ? (
              `Speaks ${profileData?.spokenLanguages.map((lang) => lang?.displayName).join(",")}`
            ) : (
              <p className="body text-cool-grey-400">Edit your profile to show</p>
            )}
          </div>
        </div>
      </div>
      <div className="my-4 border-b border-medium border-cool-grey-300" />
      <div className="flex flex-col gap-4">
        <div className="flex gap-2 items-center">
          <Icons name="user-outlined" height={24} width={24} fill="#292D38" />
          <div className="heading-7">About myself</div>
        </div>
        <div className="body">
          {profileData?.bio ? (
            <p>{profileData.bio}</p>
          ) : (
            <p className="body text-cool-grey-400">Edit your profile to show</p>
          )}
        </div>
      </div>
    </div>
  );
};
