import { Link } from "react-router-dom";
import { Button } from "./Button";
import { useUserState } from "context/userContext";
import MediaCardComponent from "./MediaCardComponent";

import useScreenType from "hooks/useScreenType";
import { Carousel, CarouselApi, CarouselContent, CarouselItem } from "./Carousel";
import type { Resource } from "types/__generated__/graphql";
import { useState } from "react";
import { cn } from "libs/classMerger";

interface FeaturedListComponentProps {
  title: string;
  description: string;
  backdropImagePath: string;
  buttonLink: string;
  data: Resource[];
  onHover: (image: string) => void;
  onClick: (image: string) => void;
  onLeave: () => void;
  type: "meditations" | "soundscapes";
  handleMoreAction: () => void;
}

const FeaturedListComponent = ({
  title,
  description,
  backdropImagePath,
  data,
  onHover,
  onLeave,
  onClick,
  type,
  handleMoreAction,
}: FeaturedListComponentProps) => {
  const screenType = useScreenType();
  const { userAccessState } = useUserState();
  const [carouselApi, setCarouselApi] = useState<CarouselApi>();

  const isMobile = screenType.isMobile || screenType.isTablet;

  return (
    <div
      className="relative px-6 py-10 md:px-10 md:py-12 h-auto md:min-h-[440px] xl:h-[500px] lg:px-10 lg:py-16 bg-black/30 rounded-3xl bg-cover bg-no-repeat bg-top"
      style={{ backgroundImage: `url('${backdropImagePath}')`, transition: "0.5s ease" }}
    >
      <div className="absolute inset-x-0 bottom-0 h-[75%] bg-gradient-to-t from-black to-transparent rounded-3xl" />
      <div className="absolute inset-y-0 right-0 w-[75%] bg-gradient-to-l from-black to-transparent rounded-3xl" />
      <div className="relative flex flex-col z-0 h-full justify-end md:gap-10 overflow-hidden">
        <div className="rounded-2xl justify-between gap-4 inline-flex flex-col lg:flex-row w-full align-end">
          <div className={cn("h-[100%] flex-col justify-end items-start gap-1 flex",
            !isMobile && "w-[350px] md:w-[400px]"
          )}>
            <h2 className="self-stretch text-white text-4xl md:text-5xl font-medium font-['Sharp Grotesk Cyr Medium'] leading-[45px] tracking-tight">
              {title}
            </h2>
            <div className="text-[#ebedef] flex font-['Sharp Grotesk Cyr Book']">{description}</div>
            <div className="flex-col justify-start items-start hidden lg:flex mt-3">
              <Button
                variant="neutral"
                className="bg-white text-cool-grey-700 hover:bg-white-90a"
                onClick={handleMoreAction}
              >
                Discover more
              </Button>
            </div>
          </div>
          <div className="mt-10 md:mt-0 h-[243px] items-center flex overflow-hidden">
            <Carousel
              opts={{
                align: "start",
                loop: false,
                active: isMobile,
                dragFree: !isMobile
              }}
              orientation="horizontal"
              className="w-full"
              setApi={setCarouselApi}

            >
              <CarouselContent className="gap-3 md:gap-4">
                {data.map((item) => (
                  <CarouselItem key={item.id} className="basis-auto">
                    <MediaCardComponent
                      key={item.id}
                      type={type}
                      onHover={(i) => onHover(i)}
                      onLeave={onLeave}
                      onClick={onClick}
                      userAccessState={userAccessState}
                      trackData={item}
                    />
                  </CarouselItem>
                ))}
              </CarouselContent>
            </Carousel>
          </div>
        </div>
        <div className={cn("flex-col justify-start items-start flex lg:hidden",
          isMobile && "pt-6"
        )}>
          <Button
            variant="neutral"
            className="bg-white text-cool-grey-700 hover:bg-white-90a w-full md:w-auto"
            onClick={handleMoreAction}
          >
            Discover more
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FeaturedListComponent;
