import { useState } from "react";
import { useToast } from "hooks/use-toast";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "components/Tooltip";
import Icons from "components/icons/Icons";
import { cn } from "libs/classMerger";
import { QuestLandingPage } from "tabs/Quest/QuestLanding/QuestLandingPage";
import { Button } from "components/Button";
import { VideoPlayer } from "components/VideoPlayer";
import type { ICategoryNode } from "types/interfaces";
import { getFormattedDate } from "utils/getFormattedDate";
import { getRendition } from "utils/mediaHelpers";
import {
  useEnrollUserMutation,
  useUnenrollUserMutation,
  type B2bQuest,
} from "types/__generated__/graphql";

export const QuestHoverCard = ({
  quest,
  questTrailerData,
  category,
  questAssignmentEndDate,
}: {
  quest: B2bQuest;
  category?: ICategoryNode;
  questTrailerData?: B2bQuest;
  questAssignmentEndDate?: string;
}) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const userProgress = quest.userProgress;
  const assignment = quest.currentAssignment?.endDate || questAssignmentEndDate;
  const isOverdue = assignment && new Date() > new Date(assignment);

  const [muted, setMuted] = useState(true);

  const [
    enrollUserMutation,
    { data: enrollUserResult, loading: enrollLoading, error: enrollError },
  ] = useEnrollUserMutation({
    onCompleted: () => {
      const pageId = quest.pages?.[0]?.id;
      const url = pageId
        ? `/quests/${quest.language}/${quest.slug}?pageId=${pageId}`
        : `/quests/${quest.language}/${quest.slug}`;

      navigate(url);
    },
  });

  const [
    unenrollUserMutation,
    {
      data: unenrollUserResult,
      loading: unenrollLoading,
      error: unenrollError,
    },
  ] = useUnenrollUserMutation();

  const handleStartProgram = async () => {
    if (!quest.id) return;

    try {
      await enrollUserMutation({
        variables: {
          input: {
            releaseId: quest.releases?.[0]?.id || "",
          },
        },
      });
    } catch (error) {
      toast({
        variant: "destructive",
        description: "Failed to enroll user. Please try again!",
      });
    }
  };

  const restartProgram = async () => {
    if (!quest.id) return;

    try {
      await unenrollUserMutation({
        variables: {
          input: {
            questId: quest.id,
          },
        },
      });

      await enrollUserMutation({
        variables: {
          input: {
            releaseId: quest.releases?.[0]?.id || "",
          },
        },
      });
    } catch (error) {
      console.error("Error while restarting program", error);
    }
  };

  const rendition = getRendition(questTrailerData?.trailerAsset?.renditions, [
    "application/x-mpegURL",
    "video/mp4",
  ]);

  const handleQuestNavigation = () => {
    const pageId = userProgress?.resumePage?.id;
    const url = pageId
      ? `/quests/${quest.language}/${quest.slug}?pageId=${pageId}`
      : `/quests/${quest.language}/${quest.slug}`;

    navigate(url);
  };

  const handleExporeSimilarPrograms = () => {
    navigate(`/programs?category=${quest.categories?.[0]?.id}`);
  };

  return (
    <div className="rounded-2xl shadow-md">
      <div className="rounded-2xl -my-10 w-full bg-white shadow flex-col justify-start items-center inline-flex">
        <div className="relative bg-black flex-col justify-end items-start flex rounded-t-2xl">
          <div className="w-full h-auto rounded-3xl">
            <VideoPlayer
              src={rendition?.src || quest.trailerAsset?.url || ""}
              posterUrl={quest.coverAsset?.url || ""}
              autoPlay={true}
              loop
              muted={muted}
              className="rounded-t-2xl"
            />
          </div>

          <div
            className="absolute bottom-0 w-full"
            style={{
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 100%)",
            }}
          >
            <div className="p-4 justify-between items-end flex w-full">
              <div className="text-white flex-col justify-start items-start flex-inline">
                {quest.wordmarkAsset && (
                  <img
                    alt={quest.name}
                    className="Union  w-full h-[40px]"
                    src={quest.wordmarkAsset?.url}
                  />
                )}
              </div>

              <button
                onClick={() => setMuted(!muted)}
                type="button"
                className="rounded-3xl backdrop-blur-md bg-transparent justify-center items-center grid"
              >
                <div className="rounded-3xl p-2 border border-white/25 flex">
                  <div className="w-5 justify-center items-center flex">
                    {
                      <Icons
                        name={muted ? "volume-x-filled" : "volume-2-filled"}
                        height={20}
                        width={20}
                        fill="white"
                      />
                    }
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        {assignment && (
          <div
            className={cn(
              "backdrop-blur self-stretch px-4 py-2 justify-start items-start inline-flex",
              isOverdue ? "bg-red-900" : "bg-brown-900"
            )}
          >
            <div className="grow shrink basis-0 h-4 justify-between items-center flex">
              <div className="justify-start items-center gap-1.5 flex">
                <div className="justify-center items-center flex">
                  <Icons
                    name="calendar-reminder-outlined"
                    width={12}
                    height={12}
                    fill={isOverdue ? "#FFADA7" : "white"}
                  />
                </div>

                <div
                  className={cn(
                    "title-9",
                    isOverdue ? "text-red-200" : "text-white"
                  )}
                >
                  Assigned to you
                </div>
              </div>
              <div
                className={cn(
                  "caption-disclaimer",
                  isOverdue ? "text-red-200" : "text-white"
                )}
              >
                {isOverdue ? "Overdue" : "Complete by"}{" "}
                {getFormattedDate(assignment ?? "")}
              </div>
            </div>
          </div>
        )}
        <div className="self-stretch px-4 pt-4 pb-6 rounded-2xl flex-col justify-start items-center gap-2.5 flex">
          <div className="self-stretch flex-col justify-start items-start gap-3 flex">
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="justify-start items-center gap-1 flex">
                {userProgress?.completed ? (
                  <Button
                    onClick={handleExporeSimilarPrograms}
                    type="button"
                    disabled={unenrollLoading || enrollLoading}
                  >
                    Explore similar programs
                  </Button>
                ) : // <Button
                //   onClick={restartProgram}
                //   type="button"
                //   disabled={unenrollLoading || enrollLoading}
                //   className={
                //     "bg-cool-grey-700 rounded-[100px] button-text-medium"
                //   }
                // >
                //   <div className="NeutralButton flex-col justify-start items-start inline-flex ">
                //     <div className="Basebutton h-9 px-3 py-1.5 justify-center items-center inline-flex">
                //       <div className="PastReplay w-5 h-5 pl-[0.42px] pr-[0.62px] py-[0.62px] justify-center items-center flex">
                //         <Icons
                //           name="past-replay-filled"
                //           height={20}
                //           width={20}
                //           fill="white"
                //         />
                //       </div>
                //       <div className="Label px-1 justify-center items-center flex">
                //         <div className="Button text-center text-white">
                //           Restart program
                //         </div>
                //       </div>
                //     </div>
                //   </div>
                // </Button>
                userProgress?.started ? (
                  <button
                    type="button"
                    onClick={handleQuestNavigation}
                    className={"bg-red-600 rounded-[100px] button-text-medium"}
                  >
                    <div className="NeutralButton flex-col justify-start items-start inline-flex ">
                      <div className="Basebutton h-9 px-3 py-1.5 justify-center items-center inline-flex">
                        <div className="PastReplay w-5 h-5 pl-[0.42px] pr-[0.62px] py-[0.62px] justify-center items-center flex">
                          <Icons
                            name="play-filled"
                            width={20}
                            height={21}
                            fill="white"
                          />
                        </div>
                        <div className="Label px-1 justify-center items-center flex">
                          <div className="Button text-center text-white">
                            {`Continue to lesson ${userProgress?.resumePage?.position}`}
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                ) : (
                  <button
                    type="button"
                    className={"bg-red-600 rounded-[100px] button-text-medium"}
                    onClick={handleStartProgram}
                    disabled={enrollLoading}
                  >
                    <div className="NeutralButton flex-col justify-start items-start inline-flex ">
                      <div className="Basebutton h-9 px-3 py-1.5 justify-center items-center inline-flex">
                        <div className="PastReplay w-5 h-5 pl-[0.42px] pr-[0.62px] py-[0.62px] justify-center items-center flex">
                          <Icons
                            name="play-filled"
                            width={20}
                            height={21}
                            fill="white"
                          />
                        </div>
                        <div className="Label px-1 justify-center items-center flex">
                          <div className="Button text-center text-white">
                            Start program
                          </div>
                        </div>
                      </div>
                    </div>
                  </button>
                )}
                <QuestLandingPage
                  questArgs={{
                    slug: quest.slug,
                    language: quest.language,
                  }}
                  trigger={
                    <button type="button" className="rounded-lg">
                      <Tooltip
                        tooltipContent={
                          <p className="caption-disclaimer">Learn more</p>
                        }
                      >
                        <div className="p-2 rounded-[128px] border border-black/10 justify-start items-center gap-2.5 inline-flex">
                          <div className="justify-center items-center flex">
                            <Icons
                              name="list-filled"
                              width={20}
                              height={21}
                              fill="#595E67"
                            />
                          </div>
                        </div>
                      </Tooltip>
                    </button>
                  }
                />
              </div>
            </div>
            <div className="self-stretch flex-col justify-start items-start gap-1 flex">
              {category && (
                <div className={cn(category.name, "heading-9")}>
                  <span>{category.name.toLowerCase().replace("-b2b", "")}</span>
                </div>
              )}

              <div className="self-stretch justify-start items-center gap-1 inline-flex">
                <div className="heading-7">{quest.name}</div>
              </div>
              <div className="text-content-quaternary text-sm body-small leading-tight tracking-tight">
                {quest.authors?.[0]?.name}
              </div>
            </div>
            <div className="self-stretch text-secondary body-small text-sm leading-tight tracking-tight line-clamp-4">
              {quest.description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
