import LockedModal from "components/LockedModal";
import { ProgressBar } from "components/ProgressBar";
import Icons from "components/icons/Icons";
import { UserAccessStateEnum } from "context/userContext";
import { cn } from "libs/classMerger";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFormattedDate } from "tabs/Programs/programs-utils";
import type { B2bQuest } from "types/__generated__/graphql";

export function QuestCardBase({
  quest,
  questAssignmentEndDate,
  userAccessState,
}: {
  quest: B2bQuest;
  questAssignmentEndDate?: string;
  userAccessState: UserAccessStateEnum;
}) {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const coverUrl = quest.coverAsset?.url;
  const author = quest.authors?.[0].name;
  const progress = quest.userProgress;
  const name = quest.name;
  const assignment = quest.currentAssignment?.endDate || questAssignmentEndDate;
  const completedCount =
    quest.type === "weekly"
      ? progress?.totalLessonsCompleted
      : progress?.totalDaysCompleted;
  const totalCount =
    quest.type === "weekly" ? progress?.totalLessons : progress?.totalDays;
  const isOverdue = assignment && new Date() > new Date(assignment);

  const handleClickQuestCard = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (
      userAccessState !== UserAccessStateEnum.Freemium && userAccessState !== UserAccessStateEnum.Premium
    ) {
      return setIsModalOpen(true);
    }
    const pageId = quest.userProgress?.resumePage?.id;
    const url = pageId
      ? `/quests/${quest.language}/${quest.slug}?pageId=${pageId}`
      : `/quests/${quest.language}/${quest.slug}`;

    navigate(url);
  };

  return (
    <div>
      <button
        type="button"
        onClick={handleClickQuestCard}
        className="cursor-pointer rounded-2xl"
      >
        <div className="overflow-hidden flex md:gap-0 flex-col justify-start items-start">
          <div className="rounded-2xl w-full relative flex-grow h-auto mb-3 mr-3 md:mr-0 overflow-hidden">
            <img
              className="rounded-2xl object-contain transition-all ease-in-out duration-500 hover:scale-105 "
              alt={name}
              src={coverUrl}
            />
            <div className="flex flex-col items-center gap-0 rounded-2xl justify-center w-full absolute bottom-0">
              {!progress?.completed && progress?.started && (
                <ProgressBar
                  containerClasses="bg-black-18a border-t border-white-12a"
                  progressClasses="!bg-yellow-500"
                  value={completedCount}
                  maxValue={totalCount}
                />
              )}
              {assignment && (
                <div className="absolute bottom-0 bg-black/40 overlay-blur w-full text-white py-[6px] px-4 flex justify-between">
                  <div className="flex items-center gap-2">
                    <div className="w-[14px] h-[14px]">
                      <Icons
                        name="calendar-reminder-outlined"
                        width={14}
                        height={14}
                        fill={isOverdue ? "#FF8D82" : "white"}
                      />
                    </div>
                    <div
                      className={cn(
                        "title-10",
                        isOverdue ? "text-red-300" : ""
                      )}
                    >
                      {isOverdue ? "Overdue" : "Complete by"}{" "}
                      {getFormattedDate(assignment)}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex w-full flex-col flext-start gap-1 text-left">
            <div className="w-full flex flex-row justify-between gap-2">
              <p
                className="title-8 h-full md:title-7 text-cool-grey-700 text-ellipsis line-clamp-2 md:line-clamp-1"
                data-testid="quest-title"
              >
                {name}
              </p>
              {progress?.completed && (
                <div>
                  <Icons
                    name="checklist-circle-filled"
                    height={20}
                    width={20}
                    fill="#159F65"
                  />
                </div>
              )}
            </div>
            <p
              className="body-small text-cool-grey-450 text-ellipsis line-clamp-1"
              data-testid="quest-author"
            >
              {author}
            </p>
          </div>
        </div>
      </button>
      <div className="m-auto flex max-w-[480px] rounded-2xl">
        <LockedModal
          onCancel={() => setIsModalOpen(false)}
          open={isModalOpen}
          userAccessState={userAccessState}
        />
      </div>
    </div>
  );
}
